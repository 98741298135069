/* istanbul ignore file */
import React from 'react';

//actions
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Input,
	InputGroup,
	InputLeftElement,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { mdiCheck, mdiMagnify } from '@mdi/js';
import { debounce } from 'lodash';

import useDesignerStore from '@/stores/DesignerStore';
import { showNotification } from '@/stores/NotificationStore';
import useTextEditorStore from '@/stores/TextEditorStore';

import { NotificationTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';

class GoogleFontSettings extends React.PureComponent {
	fontContainer = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			nameFilter: '',
			activeFont: undefined,
			allFonts: [],
			visibleFonts: [],
			filteredFonts: [],
			pos: 0,
			loader: true,
		};
	}

	componentDidMount() {
		// if (!previousProps.show && this.props.show) {
		this.getGoogleFonts();
		// }
	}

	render() {
		return (
			<div>
				<InputGroup>
					<Input
						placeholder="Search for a font"
						onKeyUp={this.getFilteredFonts}
						value={this.state.nameFilter}
						onChange={(e) => this.onInputChange('nameFilter', e.target.value)}
					/>

					<InputLeftElement pointerEvents="none">
						<Icon path={mdiMagnify} />
					</InputLeftElement>
				</InputGroup>

				<Text mb={4} mt={5} fontWeight="semibold">
					Available fonts
				</Text>

				{this.state.loader && this.renderLoader()}

				{!this.state.loader && this.renderFontCards()}
			</div>
		);
	}

	onInputChange = (type, value) => {
		this.setState({ [type]: value });
		this.setFilteredFonts(value);
	};

	setFilteredFonts = (nameFilter) => {
		if (nameFilter && nameFilter.length >= 3) {
			const allFonts = [...this.state.allFonts];
			const filteredFonts =
				allFonts &&
				allFonts.filter((item) => {
					return item.family.toLowerCase().includes(nameFilter.toLowerCase());
				});

			this.setState({ filteredFonts });
		}
	};

	getFilteredFonts = debounce(() => {
		const filteredFonts = [...this.state.filteredFonts];
		if (filteredFonts && filteredFonts.length) {
			const fontsToLoad = filteredFonts.map((item) => {
				return item.family;
			});

			global.WebFont.load({
				google: {
					families: fontsToLoad,
				},
			});
		}
	}, 500);

	addFonts = () => {
		const font = { ...this.state.activeFont };

		let embed = font.family.replace(/ /g, '+');

		if (font.variants && font.variants.length) {
			font.variants.forEach((variant) => {
				const variantValue = variant !== 'regular' ? (variant === 'italic' ? '400i' : variant.substr(0, 4)) : '';
				embed = `${embed}${variant !== 'regular' ? (!embed.includes(':') ? ':' : ',') : ''}${variantValue}`;
			});
		}

		this.addFont(font.family, embed, font.variants);

		showNotification({ type: NotificationTypes.SUCCESS, text: 'The font was added successfully' });

		this.setState({
			// nameFilter: '',
			activeFont: undefined,
		});
	};

	addFont = (fontName, embed) => {
		const newFont = {
			value: fontName,
			label: fontName,
			embed,
		};

		useTextEditorStore.getState().addCustomFont(newFont);
	};

	getVariantName = (variant) => {
		if (variant === 'italic' || variant.length === 3) {
			return variant;
		} else {
			return `${variant.substr(0, 3)} ${variant.substr(3, variant.length - 1)}`;
		}
	};

	renderFontCards = () => {
		const addedFonts = useTextEditorStore.getState().custom_fonts;

		let fontsByState;

		if (this.state.nameFilter && this.state.nameFilter.length >= 3) {
			fontsByState = [...this.state.filteredFonts];
		} else {
			fontsByState = [...this.state.visibleFonts];
		}

		const list =
			fontsByState &&
			fontsByState.map((item, i) => {
				const isAdded =
					addedFonts &&
					addedFonts.find((addedFont) => {
						return addedFont.label === item.family;
					});

				return (
					<FontCard
						key={i}
						active={this.state.activeFont && this.state.activeFont.family === item.family}
						font={item}
						isAdded={isAdded ? true : false}
						selectedVariants={this.state.activeFont ? this.state.activeFont.variants : []}
						closeFont={() => this.toggleFont([], undefined)}
						addFont={this.addFonts}
					/>
				);
			});

		const activeFontIndex = (fontsByState || []).findIndex((f) => f.family === this.state.activeFont?.family) ?? null;

		return (
			<div ref={(fontContainer) => (this.fontContainer = fontContainer)}>
				<Box h="fit-content" maxH="sm" overflowY="auto" px={4} mx={-4} onScroll={this.onScroll}>
					<Accordion allowToggle index={activeFontIndex} onChange={(idx) => this.toggleFont(fontsByState, idx)}>
						{list}
					</Accordion>
				</Box>
			</div>
		);
	};

	toggleFont = (fontsByState, index) => {
		if (typeof index !== 'number') this.setState({ activeFont: undefined });
		else {
			const activeFont = (fontsByState || [])[index];
			this.setState({ activeFont: { family: activeFont?.family || undefined, variants: [] } });
		}
	};

	renderLoader = () => {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" h="72">
				<Spinner />
			</Box>
		);
	};

	onScroll = () => {
		if (this.fontContainer.firstChild.scrollHeight - this.fontContainer.firstChild.scrollTop <= 450 && !this.state.nameFilter) {
			this.setVisibleFonts(this.state.allFonts);
		}
	};

	getGoogleFonts = () => {
		const successCb = (fonts) => {
			this.setVisibleFonts(fonts);

			this.setState({ allFonts: fonts, loader: false });
		};

		const errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to get fonts from Google' });
		};

		useDesignerStore.getState().getGoogleFonts(successCb, errorCb);
	};

	setVisibleFonts = (fonts) => {
		const visibleFonts = [...this.state.visibleFonts, ...fonts.slice(this.state.pos, this.state.pos + 9)];

		const fontsToLoad = visibleFonts.map((item) => {
			return item.family;
		});

		window.WebFont.load({
			google: {
				families: fontsToLoad,
			},
		});

		this.setState({ visibleFonts, pos: this.state.pos + 9 });
	};
}

const FontCard = (props) => {
	const demo = 'a b c d e f g h i j k l m n o p q r s t u v w x y z';
	const numberDemo = '0 1 2 3 4 5 6 7 8 9';

	return (
		<AccordionItem data-testid={'settings-font-item'}>
			<AccordionButton justifyContent="start" textAlign="left">
				{props.font.family}

				<Text ml="auto" fontSize="xs" align="center" fontFamily={props.font.family}>
					A B C D a b c d
				</Text>

				{props.isAdded && <Icon ml={1.5} path={mdiCheck} color="success" />}

				<AccordionIcon ml={1.5} />
			</AccordionButton>

			<AccordionPanel motionProps={{ unmountOnExit: true }}>
				<Text fontSize="xs" fontFamily={props.font.family}>
					{demo.toUpperCase()}
				</Text>

				<Text fontSize="xs" fontFamily={props.font.family}>
					{demo}
				</Text>

				<Text fontSize="xs" fontFamily={props.font.family} mb={5}>
					{numberDemo}
				</Text>

				<Box display="flex" justifyContent="flex-end" gap="2">
					<Button size="sm" variant="ghost" onClick={props.closeFont}>
						Cancel
					</Button>

					<Button size="sm" onClick={props.addFont}>
						Add
					</Button>
				</Box>
			</AccordionPanel>
		</AccordionItem>
	);
};

export default GoogleFontSettings;
