import { create } from 'zustand';

import { ModalTypes } from '@/util/resources';
import WebStorage from '@/util/webStorage';

let dontAskRow = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').row) || false;

let dontAskPage = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').page) || false;

let dontAskElement = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').element) || false;

let dontAskResponsive = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').responsive) || false;

let dontAskJpgWarning = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').jpgWarning) || false;

let dontAskNonEmbedVideo = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').nonEmbedVideo) || false;

let dontAskMobileView = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').mobileView) || false;

let dontAskMobilePadding = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').mobilePadding) || false;

let dontAskCheckPreview = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').checkPreview) || false;

let dontAskOutlookBorder = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').outlookBorder) || false;

let dontAskStructureWidth = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').structureWidth) || false;

let dontAskBorderRadius = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').borderRadius) || false;

let dontAskRowBackgroundImage = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').rowBackgroundImage) || false;

let dontAskCustomFontSupport = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').customFontSupport) || false;

let sendTest = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').sendTest) || false;

let textResponsiveLength = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').textResponsiveLength) || false;

let wideRowBackgound = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').wideRowBackgound) || false;

let retinaImages = (WebStorage.get('dont_ask_again') && WebStorage.get('dont_ask_again').retinaImages) || false;

const useModalStore = create((set, get) => ({
	[ModalTypes.IMAGE_PICKER]: {
		show: false,
		data: {},
	},
	[ModalTypes.PRODUCT]: {
		show: false,
		data: {},
	},
	[ModalTypes.STRUCTURE_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.GENERAL_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.ELEMENT_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.CROP_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.TEMPLATE_PREVIEW_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.DONT_ASK]: {
		show: false,
		data: {
			dont_ask_again: {
				row: dontAskRow,
				page: dontAskPage,
				element: dontAskElement,
				responsive: dontAskResponsive,
				borderRadius: dontAskBorderRadius,
				rowBackgroundImage: dontAskRowBackgroundImage,
				customFontSupport: dontAskCustomFontSupport,
				jpgWarning: dontAskJpgWarning,
				nonEmbedVideo: dontAskNonEmbedVideo,
				mobileView: dontAskMobileView,
				mobilePadding: dontAskMobilePadding,
				checkPreview: dontAskCheckPreview,
				sendTest: sendTest,
				outlookBorder: dontAskOutlookBorder,
				structureWidth: dontAskStructureWidth,
				textResponsiveLength: textResponsiveLength,
				wideRowBackgound: wideRowBackgound,
				retinaImages: retinaImages,
			},
		},
	},
	[ModalTypes.GOOGLE_FONTS_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.FORM_SETTINGS_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.FILE_UPLOAD]: {
		show: false,
		data: {},
	},
	[ModalTypes.TEMPLATE_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.CUSTOM_ROW_MODAL]: {
		show: false,
		data: {},
	},
	[ModalTypes.CUSTOM_ELEMENT_MODAL]: {
		show: false,
		data: {},
	},
	closeModal: (modalType) => {
		return set(() => ({
			[modalType]: {
				show: false,
				data: {},
			},
		}));
	},
	showStructureModal: ({ rowId, first }) => {
		return set((state) => ({
			[ModalTypes.STRUCTURE_MODAL]: {
				show: !state[ModalTypes.STRUCTURE_MODAL].show,
				data: { rowId, first },
			},
		}));
	},
	closeStructureModal: () => {
		return set(() => ({
			[ModalTypes.STRUCTURE_MODAL]: {
				show: false,
				data: {},
			},
		}));
	},
	showFormSettingsModal: (data) => {
		return set(() => ({
			[ModalTypes.FORM_SETTINGS_MODAL]: {
				// show: !state[ModalTypes.FORM_SETTINGS_MODAL].show,
				show: true,
				data,
			},
		}));
	},
	showElementModal: (data) => {
		return set((state) => ({
			[ModalTypes.ELEMENT_MODAL]: {
				show: !state[ModalTypes.ELEMENT_MODAL].show,
				data,
			},
		}));
	},
	closeElementModal: () => {
		return set(() => ({
			[ModalTypes.ELEMENT_MODAL]: {
				show: false,
				data: {},
			},
		}));
	},
	showGeneralModal: (data) => {
		return set((state) => ({
			[ModalTypes.GENERAL_MODAL]: {
				show: !state[ModalTypes.GENERAL_MODAL].show,
				data,
			},
		}));
	},
	closeGeneralModal: () => {
		return set(() => ({
			[ModalTypes.GENERAL_MODAL]: {
				show: false,
				data: {},
			},
		}));
	},
	showImagePickerModal: (data) => {
		return set((state) => ({
			[ModalTypes.IMAGE_PICKER]: {
				show: !state[ModalTypes.IMAGE_PICKER].show,
				data,
			},
		}));
	},
	closeImagePickerModal: () => {
		return set(() => ({
			[ModalTypes.IMAGE_PICKER]: {
				show: false,
				data: {},
			},
		}));
	},
	showProductModal: (data) => {
		return set((state) => ({
			[ModalTypes.PRODUCT]: {
				show: !state[ModalTypes.PRODUCT].show,
				data,
			},
		}));
	},
	closeProductModal: () => {
		return set(() => ({
			[ModalTypes.PRODUCT]: {
				show: false,
				data: {},
			},
		}));
	},
	showGoogleFontsModal: (data) => {
		return set((state) => ({
			[ModalTypes.GOOGLE_FONTS_MODAL]: {
				show: !state[ModalTypes.GOOGLE_FONTS_MODAL].show,
				data,
			},
		}));
	},
	showCropModal: (data) => {
		return set((state) => ({
			[ModalTypes.CROP_MODAL]: {
				show: !state[ModalTypes.CROP_MODAL].show,
				data,
			},
		}));
	},
	closeCropper: () => {
		return set(() => ({
			[ModalTypes.CROP_MODAL]: {
				show: false,
				data: {},
			},
		}));
	},
	showTemplatePreviewModal: ({ show, template }) => {
		return set(() => ({
			[ModalTypes.TEMPLATE_PREVIEW_MODAL]: {
				show,
				data: template,
			},
		}));
	},
	closeTemplatePreviewModal: () => {
		return set(() => ({
			[ModalTypes.TEMPLATE_PREVIEW_MODAL]: {
				show: false,
				data: {},
			},
		}));
	},
	showDontAskModal: (data) => {
		return set((state) => ({
			[ModalTypes.DONT_ASK]: {
				show: !state[ModalTypes.DONT_ASK].show,
				data: {
					...state[ModalTypes.DONT_ASK].data,
					...data,
				},
			},
		}));
	},
	closeDontAskModal: (dontAskAgain) => {
		return set((state) => {
			let currentDontAsk = WebStorage.get('dont_ask_again') || {};
			WebStorage.set('dont_ask_again', { ...currentDontAsk, ...dontAskAgain });

			let data = {
				dont_ask_again: {
					...state[ModalTypes.DONT_ASK].data.dont_ask_again,
					...dontAskAgain,
				},
			};

			return {
				[ModalTypes.DONT_ASK]: {
					show: false,
					data,
				},
			};
		});
	},
	insertComponent: ({ row, componentData }) => {
		return componentData && get().showDontAskModalOnTextInsert({ row, componentData });
	},
	moveComponent: ({ row, toAddress }) => {
		const componentData = row && row.slots && row.slots[toAddress.slot] && row.slots[toAddress.slot].components[toAddress.component];
		return componentData && get().showDontAskModalOnTextInsert({ row, componentData });
	},
	showDontAskModalOnTextInsert() {
		// console.log(row, componentData);
	},
	showTemplateModal: (data) => {
		return set((state) => ({
			[ModalTypes.TEMPLATE_MODAL]: {
				show: !state[ModalTypes.TEMPLATE_MODAL].show,
				data,
			},
		}));
	},
	showCustomRowModal: (data) => {
		return set((state) => ({
			[ModalTypes.CUSTOM_ROW_MODAL]: {
				show: !state[ModalTypes.CUSTOM_ROW_MODAL].show,
				data,
			},
		}));
	},
	showCustomElementModal: (data) => {
		return set((state) => ({
			[ModalTypes.CUSTOM_ELEMENT_MODAL]: {
				show: !state[ModalTypes.CUSTOM_ELEMENT_MODAL].show,
				data,
			},
		}));
	},
}));

export default useModalStore;
