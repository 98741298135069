import React from 'react';

import { getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';

import { getFormFieldFontStyles } from '@/util/helper';
import { FormFieldTypes } from '@/util/resources';

import Element from './Element.react';

export const Checkbox = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		labelWidth,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFontSize,
		fieldFontColour,
		fieldBorderWidth,
		fieldBorderColour,
		fieldBorderRadius,
		fieldPadding,
		fieldBgColor,
		// fieldWidth,
		asteriskColor,
		formId,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		box_shadow,
		background_color,
	} = props;

	const hiddenStyles = props.hidden
		? {
				opacity: 0.7,
			}
		: {};

	return (
		<div
			id={`${props.name.replace(/ /g, '')}-${formId}`}
			style={{
				width: '100%',
				backgroundColor: background_color,
				position: 'relative',
				pointerEvents: 'none',
				...getPadding(props, true),
				...hiddenStyles,
			}}
			className="form-input-wrapper lp-flex-1"
		>
			{props.legalText && <div dangerouslySetInnerHTML={{ __html: props.legalText }} />}
			<label
				className={`form-input-wrapper-element form-checkbox-element lp-flex-container lp-flex-direction-horizontal lp-flex-align-center form-input-${props.formId}`}
				style={{
					width: '100%',
					WebkitBoxAlign: 'center',
					MsFlexAlign: 'center',
					alignItems: 'center',
					padding: 0,
					display: 'flex',
					minHeight: props.legalText ? labelWidth : undefined,
				}}
			>
				<div className="checkbox-wrapper" onClick={() => props.onClick && props.onClick(FormFieldTypes.field)}>
					<input
						title={props.placeholder}
						data-label={props.dataLabel ? props.dataLabel : props.label}
						data-primary={props.primary}
						data-type={props.type}
						data-required={props.required}
						// data-validate={props.validate || false}
						placeholder={props.placeholder}
						className="form-input-checkbox-element form-input-element"
						disabled={props.disabled}
						style={{
							opacity: 1,
							display: 'block',
							cursor: 'pointer',
							minWidth: 0,
							outline: 'none',
							margin: 0,
							appearance: 'none',
							background: fieldBgColor,
							padding: fieldPadding,
							color: fieldFontColour,
							fontSize: fieldFontSize,
							borderWidth: fieldBorderWidth,
							borderColor: fieldBorderColour,
							borderStyle: 'solid',
							borderRadius: fieldBorderRadius,
							fontWeight: fieldFontWeight,
							fontStyle: fieldFontStyle,
							textDecoration: fieldTextDecoration,
							boxShadow: getShadowValFromProps(box_shadow),
							// width: `0px`
						}}
						type={props.hidden ? 'hidden' : props.type}
						name={props.name}
						checked={props.value}
						data-readOnly={props.readOnly}
						readOnly={props.readOnly}
					/>
					<span
						className="mdi mdi-check"
						style={{
							display: 'block',
							position: 'absolute',
							left: '50%',
							top: '50%',
							marginLeft: 0 - fieldFontSize / 2,
							marginTop: 0 - fieldFontSize / 2,
							color: fieldFontColour,
							fontSize: fieldFontSize,
							cursor: 'pointer',
							lineHeight: 1,
						}}
					/>
				</div>
				<span
					style={{
						userSelect: 'none',
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
						color: labelFontColour,
						fontSize: labelFontSize,
						fontWeight: labelFontWeight,
						fontStyle: labelFontStyle,
						textDecoration: labelTextDecoration,
						display: labelHide ? 'none' : 'flex',
						marginLeft: 10,
						...getFormFieldFontStyles(labelFont),
					}}
					className="form-field-label lp-flex-container"
					onClick={() => props.onClick && props.onClick(FormFieldTypes.label)}
				>
					{props.label}
					{props.required && (
						<span
							className="form-field-required"
							style={{
								color: asteriskColor,
								textDecoration: 'none',
								fontWeight: 'normal',
								fontStyle: 'normal',
								// fontFamily: 'inherit',
							}}
						>
							{' '}
							*
						</span>
					)}
				</span>
			</label>
			{props.description && (
				<div
					style={{
						color: descriptionFontColour,
						fontSize: descriptionFontSize,
						fontFamily: descriptionFontFamily,
						marginTop: descriptionSpacing,
						fontStyle: descriptionFontStyle,
						fontWeight: descriptionFontWeight,
						textDecoration: descriptionTextDecoration,
						display: props.descriptionHide ? 'none' : 'block',
					}}
				>
					{props.description}
				</div>
			)}
		</div>
	);
};

export default Element(Checkbox);
