import { Button, Flex, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Text, Tooltip } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';

import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';

import { Icon } from '@/components/gui/shared/Icon';

function getTenantUrl(tenant) {
	const params = new URLSearchParams(window.location.search);

	params.set('tenantName', tenant.name);
	params.set('organization', tenant.organizationId);

	return `${window.location.origin}?${params.toString()}`;
}

export const EnvSwitcher: React.FC = () => {
	const user = getUser();
	const tenants = useUserStore((state) => state.tenants);
	const tenantId = user[claimsKeys.TENANT_ID];
	const currentTenant = tenants.find((tenant) => tenant.id === tenantId);

	const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tenant) => {
		if (currentTenant.id === tenant.id) e.preventDefault();
	};

	if (!currentTenant) return null;

	return (
		<Menu>
			<Tooltip label={currentTenant.displayName}>
				<MenuButton
					as={Button}
					size="xs"
					variant="ghost"
					px={2}
					rightIcon={<Icon path={mdiChevronDown} />}
					mr={4}
					data-testid="env-switcher-btn"
				>
					<Flex>
						<Text isTruncated maxWidth="7ch">
							{currentTenant.annotations['XMCloud.ProjectName']}
						</Text>

						<span>/</span>

						<Text isTruncated maxWidth="7ch">
							{currentTenant.annotations['XMCloud.EnvironmentName']}
						</Text>
					</Flex>
				</MenuButton>
			</Tooltip>

			<MenuList>
				<MenuOptionGroup title="ENVIRONMENTS" value={currentTenant.id}>
					{tenants.map((tenant) => (
						<MenuItemOption
							as="a"
							key={tenant.id}
							value={tenant.id}
							href={getTenantUrl(tenant)}
							data-testid={tenant.name}
							onClick={(e) => onClick(e, tenant)}
						>
							{tenant.displayName}
						</MenuItemOption>
					))}
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
};
