import React from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Heading,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Tag,
	TagLabel,
	Tooltip,
} from '@chakra-ui/react';
import { mdiDotsHorizontal, mdiHeart, mdiHeartOutline } from '@mdi/js';
import config from 'config/config';
import { ErrorMessages, ModalMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { useLocation, useNavigate } from 'react-router-dom';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';
import useTemplateStore from '@/stores/TemplateStore';

import { getUser } from '@/util/auth/auth';
import { formatTestId, leadingDebounce, pathPrefix } from '@/util/helper';
import { ApiAuthorisationModes, Modals, NotificationTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';

const TemplateThumbWrapper = (props) => {
	const templateProps = useTemplateStore();
	const navigate = useNavigate();
	const location = useLocation();
	return <TemplateThumb {...templateProps} {...props} navigate={navigate} location={location} />;
};

class TemplateThumb extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			imageLoading: true,
			modalOpen: false,
		};
	}

	render() {
		let generatedImage = this.props.template && this.props.template.generatedImage;
		let imageSrc =
			(this.props.template && this.props.template.imageSrc) ||
			generatedImage ||
			`${config.asset_url}/images/placeholder/templateplaceholderdesktop.png`;
		let imageMobileSrc =
			(this.props.template && this.props.template.imageMobileSrc) || `${config.asset_url}/images/placeholder/templateplaceholdermobile.png`;

		const hiddenMobileImage = this.props.template
			? (this.props.template.imageSrc && !this.props.template.imageMobileSrc) ||
				(!this.props.template.imageSrc && !this.props.template.imageMobileSrc && generatedImage)
			: null;

		let favorite = this.props.favouriteTemplates && this.props.template && this.props.favouriteTemplates.includes(this.props.template.id);

		const userData = getUser();

		let canEdit = true;

		if (this.props.template && this.props.template.createdBy && this.props.template.createdBy.id !== userData.sub) {
			canEdit = false;
		}

		return (
			<>
				<Card variant="outline">
					<CardBody
						className={`template-thumb ${!this.props.fromScratch ? 'saved-template' : ''}`}
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<Box mb={3.5}>
							<Box mb={3.5} display="flex" alignItems={'center'} justifyContent="space-between">
								<Heading size="sm" display="block" w="80%" noOfLines={1} className="notranslate">
									{this.props.template ? this.props.template.title : 'Create from scratch'}
								</Heading>
								{!this.props.fromScratch ? (
									<Tooltip label={favorite ? 'Unfavorite' : 'Favorite'}>
										<IconButton
											variant="ghost"
											size="sm"
											colorScheme={favorite ? 'red' : 'neutral'}
											icon={<Icon path={favorite ? mdiHeart : mdiHeartOutline} />}
											aria-label={favorite ? 'Favorite' : 'Unfavorite'}
											onClick={this.toggleFavouriteTemplate}
											mr="1"
										/>
									</Tooltip>
								) : null}
								{!this.props.fromScratch && this.props.template.AuthorisationMode !== ApiAuthorisationModes.Public && canEdit && (
									<Box data-testid="actions-box">
										<Menu>
											<Tooltip label="More options">
												<MenuButton as={IconButton} size="sm" variant="ghost" aria-label={'More options'}>
													<Icon path={mdiDotsHorizontal} />
												</MenuButton>
											</Tooltip>
											<MenuList>
												<MenuItem data-testid="delete-template" onClick={this.removeTemplate}>
													Delete
												</MenuItem>
												<MenuItem
													data-testid="share-template"
													onClick={() => useModalStore.getState().showTemplateModal({ template: this.props.template })}
												>
													Share
												</MenuItem>
											</MenuList>
										</Menu>
									</Box>
								)}
							</Box>
							<Box display="flex" h="xs">
								<Box overflow="hidden" w={!hiddenMobileImage ? '75%' : '100%'}>
									<Image
										display={this.state.imageLoading ? 'none' : ''}
										w="100%"
										src={imageSrc}
										onLoad={() => this.setState({ imageLoading: false })}
									/>
									<Box h="64" display={!this.state.imageLoading ? 'none' : 'flex'} alignItems="center" justifyContent="center">
										<Spinner />
									</Box>
								</Box>
								{!hiddenMobileImage && (
									<Box overflow="hidden" w="20%" ml="5%">
										<Image src={imageMobileSrc} w="100%" />
									</Box>
								)}
							</Box>
						</Box>
						{!this.props.fromScratch ? this.renderNormalThumb() : this.scratchThumb()}
					</CardBody>
				</Card>
			</>
		);
	}

	renderNormalThumb = () => {
		return (
			<Box>
				{this.props.template.categories.length ? (
					<Box mb={3.5} className="notranslate">
						{this.props.template.categories.map((item, i) => {
							const isActive = this.props.currentCategory === item;
							return (
								<Tag
									size={'sm'}
									key={`${i}-tag`}
									variant="solid"
									onClick={() => this.selectCategory(item)}
									colorScheme={isActive ? 'primary' : 'neutral'}
									cursor={'pointer'}
									my={2}
									mx={1}
								>
									<TagLabel>{item}</TagLabel>
								</Tag>
							);
						})}
					</Box>
				) : null}
				<ButtonGroup size={'sm'} w="full">
					<Button w="100%" onClick={this.chooseTemplate} data-testid={'use-' + formatTestId(this.props.template.title)}>
						Use
					</Button>
					<Button variant={'outline'} w="100%" onClick={this.previewTemplate}>
						Preview
					</Button>
				</ButtonGroup>
			</Box>
		);
	};

	selectCategory = (category) => {
		this.props.selectCategory(category);
	};

	scratchThumb = () => {
		return (
			<Box>
				<Button
					w="100%"
					onClick={() => {
						useContentStore.getState().clearContent();
						this.props.navigate(pathPrefix() + `/${this.props.location.search}`);
					}}
				>
					Create from scratch
				</Button>
			</Box>
		);
	};

	/*wrap these methods with a leading debounce function*/
	previewTemplate = leadingDebounce(() => {
		/* istanbul ignore next */
		let successCb = () => {
			//showNotification({type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_LOADED});
		};

		/* istanbul ignore next */
		let errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_LOADED });
		};

		this.props.showTemplate(null, this.props.template, successCb, errorCb);
	}, config.debounce_times.general);

	removeTemplate = leadingDebounce(() => {
		const successCb = () => {
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_REMOVED });
		};

		const errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_REMOVED });
		};

		const onOk = () => {
			this.props.removeTemplate(this.props.template, successCb, errorCb);
		};

		useModalStore.getState().showGeneralModal({
			modal: Modals.ERROR,
			title: 'Delete template?',
			message: ModalMessages.DELETE_TEMPLATE,
			okLabel: 'Delete',
			onOk,
		});
	}, config.debounce_times.general);

	chooseTemplate = leadingDebounce(() => {
		const editorOverlay = document.querySelector('#initial-overlay');
		const messageDiv = editorOverlay && editorOverlay.querySelector('#initial-error');
		if (messageDiv) messageDiv.innerHTML = 'Loading template';

		let message = ModalMessages.SET_TEMPLATE_DESIGNER('form');

		/* istanbul ignore next */
		let errorCb = () => {
			showNotification({
				type: NotificationTypes.ERROR,
				text: WarningMessages.TEMPLATE_LOAD_ERROR,
			});
			if (editorOverlay) editorOverlay.style.display = 'none';
		};

		/* istanbul ignore next */
		let successCb = () => {
			this.props.navigate(pathPrefix() + this.props.location.search);
			if (editorOverlay) editorOverlay.style.display = 'none';
		};

		let onOk = () => {
			//display overlay while loading
			if (editorOverlay) editorOverlay.style.display = 'flex';

			useContentStore.getState().loadAndSetTemplate(null, this.props.template, successCb, errorCb);
		};

		useModalStore.getState().showGeneralModal({
			modal: Modals.INFO,
			title: 'Are you sure you want to use this template?',
			message,
			onOk,
			okLabel: 'Use template',
		});
	}, config.debounce_times.choose_template);

	toggleFavouriteTemplate = leadingDebounce(() => {
		/* istanbul ignore next */
		let errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.ACTION_FAILED });
		};

		if (this.props.favouriteTemplates && this.props.template && this.props.favouriteTemplates.includes(this.props.template.id)) {
			this.props.removeFavouriteTemplate(this.props.template.id, null, errorCb);
		} else {
			this.props.addFavouriteTemplate(this.props.template.id, null, errorCb);
		}
	}, config.debounce_times.preview_template);
}

export default TemplateThumbWrapper;
