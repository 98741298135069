import { css } from 'js-beautify';
import { getDesignerAlignment, getPadding, getShadowValFromProps, getTransformerFormFieldFontStyles } from 'shared/src/utils/shared.js';

export const generateFieldGlobalStyles = (styling) => {
	const labelDimension = styling.labelPosition === 'row' ? `width: ${styling.labelWidth}px;` : `min-height: ${styling.labelWidth}px;`;

	return css(`
		/* Styles for field general styles */
		.checkbox-field.checkbox-field.checkbox-field,
		.multi-select-field.multi-select-field.multi-select-field,
		.shared-input-field.shared-input-field.shared-input-field,
		.single-select-field.single-select-field.single-select-field {
			background-color: ${styling.background_color || 'transparent'};
		}

		/* Styles for field label */
		.global-input-label.global-input-label.global-input-label {
			color: ${styling.labelFontColour};
			font-size: ${styling.labelFontSize}px;
			font-weight: ${styling.labelFontWeight};
			font-style: ${styling.labelFontStyle};
			text-decoration: ${styling.labelTextDecoration};
			${labelDimension}
			${getTransformerFormFieldFontStyles(styling.labelFont)}
			${styling.labelHide ? 'display: none;' : ''}
		}

		/* Styles for field required asterisk */
		.global-field-required.global-field-required.global-field-required {
            color: ${styling.asteriskColor};
        }

		/* Styles for field input wrapper */
		.global-input-wrapper.global-input-wrapper.global-input-wrapper {
			background: ${styling.fieldBgColor};
			border-width: ${styling.fieldBorderWidth}px;
			border-color: ${styling.fieldBorderColour};
			border-radius: ${styling.fieldBorderRadius}px;
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}
		
		/* Styles for field input element */
		.global-input-element.global-input-element.global-input-element {
			padding: ${styling.fieldPadding}px;
			padding-left: ${styling.fieldPadding}px;
			color: ${styling.fieldFontColour};
			font-size: ${styling.fieldFontSize}px;
			font-weight: ${styling.fieldFontWeight};
			font-style: ${styling.fieldFontStyle};
			text-decoration: ${styling.fieldTextDecoration};
			${getTransformerFormFieldFontStyles(styling.fieldFont)}
		}
		
		/* Styles for field placeholder */
		.global-input-element.global-input-element.global-input-element::placeholder {
			color: ${styling.placeholderFontColour};
			font-size: ${styling.fieldFontSize}px;
			font-weight: ${styling.placeholderFontStyle};
			font-style: ${styling.placeholderFontWeight};
			text-decoration: ${styling.placeholderTextDecoration};
			font-family: ${styling.placeholderFont};
		}
		
		/* Styles for field help text */
		.global-input-description.global-input-description.global-input-description {
			font-style: ${styling.descriptionFontStyle};
			font-weight: ${styling.descriptionFontWeight};
			text-decoration: ${styling.descriptionTextDecoration};
			color: ${styling.descriptionFontColour};
			font-family: ${styling.descriptionFontFamily};
			font-size: ${styling.descriptionFontSize}px;
			margin-top: ${styling.descriptionSpacing}px;
			padding-left: ${styling.labelPosition === 'row' ? styling.labelWidth + 'px' : 'initial'};
			${styling.descriptionHide ? 'display: none;' : ''}
		}

		/* Styles for field error message */
		.global-input-error-message.global-input-error-message.global-input-error-message {
			font-style: ${styling.descriptionFontStyle};
			font-weight: ${styling.descriptionFontWeight};
			text-decoration: ${styling.descriptionTextDecoration};
			color: ${styling.descriptionFontColour};
			font-family: ${styling.descriptionFontFamily};
			font-size: ${styling.descriptionFontSize}px;
			margin-top: ${styling.descriptionSpacing}px;
			padding-left: ${styling.labelPosition === 'row' ? styling.labelWidth + 'px' : 'initial'};
			${styling.descriptionHide ? 'display: none;' : ''}
		}
	`);
};

export const generateRadioGlobalStyles = (styling) => {
	return css(`
		/* Styles for field radio wrapper */
		.radio-group-field.radio-group-field.radio-group-field {
			background-color: ${styling.background_color || 'transparent'};
		}

		/* Styles for field radio element */
		.global-radio-element.global-radio-element.global-radio-element {
			background: ${styling.fieldBgColor};
			border-width: ${styling.fieldBorderWidth}px;
			border-color: ${styling.fieldBorderColour};
			border-radius: ${styling.fieldBorderRadius}px;
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}

		/* Styles for field radio marker */
		.global-radio-fill.global-radio-fill.global-radio-fill::before {
			border-radius: ${styling.fieldBorderRadius - 7}px;
			margin-left: ${0 - styling.fieldFontSize / 2}px;
			margin-top: ${0 - styling.fieldFontSize / 2}px;
			background-color: ${styling.fieldFontColour};
			width: ${styling.fieldFontSize - 4}px;
			height: ${styling.fieldFontSize - 4}px;
		}
	`);
};

export const generateCheckboxGlobalStyles = (styling) => {
	return css(`
		/* Styles for field radio wrapper */
		.checkbox-group-field.checkbox-group-field.checkbox-group-field {
			background-color: ${styling.background_color || 'transparent'};
		}

		/* Styles for field checkbox element */
		.global-checkbox-element.global-checkbox-element.global-checkbox-element {
			background: ${styling.fieldBgColor};
			border-width: ${styling.fieldBorderWidth}px;
			border-color: ${styling.fieldBorderColour};
			border-radius: ${styling.fieldBorderRadius}px;
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}

		/* Styles for field checkbox marker */
		.global-checkbox-check-marker.global-checkbox-check-marker.global-checkbox-check-marker {
			display: ${styling.value ? 'block' : 'none'};
			margin-left: ${0 - styling.fieldFontSize / 2}px;
			margin-top: ${0 - styling.fieldFontSize / 2}px;
			color: ${styling.fieldFontColour};
			font-size: ${styling.fieldFontSize}px;
		}
	`);
};

export const generateRecaptchaGlobalStyles = (styling) => {
	return css(`
		/* Styles for Recaptcha wrapper */
		.global-recaptcha-wrapper.global-recaptcha-wrapper.global-recaptcha-wrapper {
			-webkit-box-align: ${getDesignerAlignment(styling.alignment)};
			-ms-flex-align: ${getDesignerAlignment(styling.alignment)};
			align-items: ${getDesignerAlignment(styling.alignment)};
            background-color: ${styling.background_color};
			${getPadding(styling)}
		}

		/* Styles for Recaptcha element */
		.global-recaptcha-element.global-recaptcha-element.global-recaptcha-element {
            box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}
	`);
};

export const generateSubmitButtonGlobalStyles = (styling) => {
	const dimensions =
		styling.width === 'custom'
			? `padding: 0;
            width: ${styling.customSize.width}px;
            height: ${styling.customSize.height}px`
			: `
                ${getPadding(styling)}
                width: ${styling.width};
            `;

	return css(`

		/* Styles for Submit and Next button wrappers */
		.global-button-wrapper.global-button-wrapper.global-button-wrapper {
			background-color: ${styling.background_color || 'transparent'};
		}

		/* Styles for Submit and Next buttons */
		.global-button-wrapper.global-button-wrapper.global-button-wrapper .submit-button, .global-button-wrapper.global-button-wrapper.global-button-wrapper .next-button {
			background-color: ${styling.color || 'transparent'};
			border-width: ${styling.borderWidth}px;
			border-color: ${styling.borderColor};
			border-radius: ${styling.borderRadius}px;
			text-decoration :${styling.textDecoration};
			color: ${styling.text_color};
			font-weight: ${styling.fontWeight};
			font-size: ${styling.fontSize}px;
			font-family: ${styling.fontFamily};
			font-style: ${styling.fontStyle};
			margin: ${styling.width !== '100%' ? `${styling.margin}px` : `0`};
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
			${dimensions}
		}
	`);
};

export const generateBackButtonGlobalStyles = (styling) => {
	const dimensions =
		styling.width === 'custom'
			? `padding: 0;
            width: ${styling.customSize.width}px;
            height: ${styling.customSize.height}px`
			: `
                ${getPadding(styling)}
                width: ${styling.width};
            `;

	return css(`
		/* Styles for Back button */
		.global-button-wrapper.global-button-wrapper.global-button-wrapper .back-button {
			background-color: ${styling.color || 'transparent'};
			border-width: ${styling.borderWidth}px;
			border-color: ${styling.borderColor};
			border-radius: ${styling.borderRadius}px;
			text-decoration :${styling.textDecoration};
			color: ${styling.text_color};
			font-weight: ${styling.fontWeight};
			font-size: ${styling.fontSize}px;
			font-family: ${styling.fontFamily};
			font-style: ${styling.fontStyle};
			margin: ${styling.width !== '100%' ? `${styling.margin}px` : `0`};
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
			${dimensions}
		}
	`);
};

export const generateImageGlobalStyles = (styling) => {
	const alignment = getDesignerAlignment(styling.alignment);
	const padding = getPadding(styling);

	return css(`
		/* Styles for Image */
		.global-image.global-image.global-image {
			background-color: ${styling.background_color || 'transparent'};
			-webkit-box-pack: ${alignment.replace('flex-', '')};
			-ms-flex-pack: ${alignment.replace('flex-', '')};
			justify-content: ${alignment};
			${padding}
		}
	
		/* Styles for Image */
		.global-image.global-image.global-image a {
			-webkit-box-pack: ${alignment.replace('flex-', '')};
			-ms-flex-pack: ${alignment.replace('flex-', '')};
			justify-content: ${alignment};
		}
	
		/* Styles for Image */
		.global-image.global-image.global-image img {
			border-radius: ${styling.imageBorderRadius}px;
			border-width: ${styling.imageBorderWidth}px;
			border-color: ${styling.imageBorderColor};
			border-style: ${styling.imageBorderWidth ? 'solid' : 'unset'};
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}
	`);
};

export const generateSocialGlobalStyles = (styling) => {
	const realSpacing = isNaN(styling.spacing) ? 0 : styling.spacing / 2;

	return css(`
		/* Styles for Social */
		.global-social.global-social.global-social {
			background-color: ${styling.background_color || 'transparent'};
			text-align: ${styling.alignment};
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}

		/* Styles for Social */
		.global-social.global-social.global-social .social-icon-spacing {
			width: ${realSpacing}px;
		}
	`);
};

export const generateTextGlobalStyles = (styling) => {
	const padding = getPadding(styling);

	return css(`
		/* Styles for Text */
		.global-text.global-text.global-text {
			${padding}
			border-width: ${styling.textBorderWidth}px;
			border-color: ${styling.textBorderColor};
			border-radius: ${styling.textBorderRadius}px;
			background-color: ${styling.background_color || 'transparent'};
			box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}
	`);
};

export const generateSpacerGlobalStyles = (styling) => {
	return css(`
		/* Styles for Spacer */
		.global-spacer.global-spacer.global-spacer {
			background-color: ${styling.background_color || 'transparent'};
            border-width: ${styling.borderWidth}px;
            border-radius: ${styling.borderRadius}px;
            border-color: ${styling.borderColor};
            border-style: ${styling.borderWidth ? 'solid' : 'unset'};
            height: ${styling.height + 2 * styling.borderWidth}px;
            box-shadow: ${getShadowValFromProps(styling.box_shadow)};
		}
	`);
};

export const generateGlobalStyles = (styling) => {
	return css(`
		${generateFieldGlobalStyles(styling.field)}
		${generateRadioGlobalStyles(styling.radio)}
		${generateCheckboxGlobalStyles(styling.checkbox)}
		${generateRecaptchaGlobalStyles(styling.recaptcha)}
		${generateSubmitButtonGlobalStyles(styling.submitButton)}
		${generateBackButtonGlobalStyles(styling.backButton)}
		${generateImageGlobalStyles(styling.image)}
		${generateSocialGlobalStyles(styling.social)}
		${generateTextGlobalStyles(styling.text)}
		${generateSpacerGlobalStyles(styling.spacer)}
	`);
};
