type FormComponent = {
	type: string;
	text_color?: string;
	color?: string;
	text?: string;
	background_color?: string;
	labelFontColour?: string;
	fieldFontColour?: string;
	fieldBgColor?: string;
	mobileProps: Partial<FormComponent>;
};

/**
 * Converts a color to RGB.
 * @param {string} color - The color code in hex or rgb/rgba format.
 * @returns {number[]} - The RGB representation.
 */
const colorToRgb = (color: string): number[] => {
	if (color === 'transparent') {
		return [255, 255, 255]; // Default to white for transparent
	}

	const tempElement = document.createElement('div');
	tempElement.style.color = color;
	document.body.appendChild(tempElement);

	// Get computed color value
	const computedColor = getComputedStyle(tempElement).color;
	document.body.removeChild(tempElement);

	// Extract RGB values
	const rgbValues = computedColor.match(/\d+/g)?.map(Number);
	if (rgbValues && rgbValues.length >= 3) {
		return [rgbValues[0], rgbValues[1], rgbValues[2]];
	}

	console.log(`Failed to extract RGB values from color: ${color}`);
};

/**
 * Calculates the relative luminance of a color.
 * @param {number[]} rgb - The RGB representation of the color.
 * @returns {number} - The relative luminance.
 */
const getLuminance = (rgb: number[]): number => {
	const [r, g, b] = rgb.map((value) => {
		const channel = value / 255;
		return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
	});
	return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

/**
 * Calculates the contrast ratio between two colors.
 * @param {string} color1 - The first color in hex or rgb/rgba format.
 * @param {string} color2 - The second color in hex or rgb/rgba format.
 * @returns {number} - The contrast ratio.
 */
const getContrastRatio = (color1: string, color2: string): number => {
	const luminance1 = getLuminance(colorToRgb(color1));
	const luminance2 = getLuminance(colorToRgb(color2));
	return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
};

const extractColor = (htmlString: string) => {
	const colorMatch = htmlString.match(/style="color:\s*([^;]+);"/);
	return colorMatch ? colorMatch[1] : null;
};

/**
 * Checks if the color contrast between the component's text and background is sufficient.
 * @param {FormComponent} component - The component object.
 * @returns {boolean} - True if the contrast is sufficient, otherwise false.
 */
export const checkColorContrast = (component: FormComponent): boolean => {
	let textColor: string;
	let fieldTextColor: string = '#000000';
	let backgroundColor: string;
	let fieldBackgroundColor: string = '#FFFFFF';

	switch (component.type) {
		case 'submit_button':
			textColor = component.text_color || '#000000';
			backgroundColor = component.color || '#FFFFFF';
			break;
		case 'text':
			textColor = extractColor(component.text || '') || component.text_color || '#000000';
			backgroundColor = component.background_color || '#FFFFFF';
			break;
		case 'checkbox':
		case 'checkbox_group':
		case 'text_input':
		case 'longtext_input':
		case 'phone_input':
		case 'email_input':
		case 'date_input':
		case 'singleselect_input':
		case 'file_upload':
		case 'radio':
		case 'number_input':
		case 'multiselect_input':
			textColor = component.labelFontColour || '#000000';
			backgroundColor = component.background_color || '#FFFFFF';

			fieldTextColor = component.fieldFontColour || '#000000';
			fieldBackgroundColor = component.fieldBgColor || '#FFFFFF';
			break;
		default:
			return true; // If the component type is not recognized, assume contrast is sufficient
	}

	const labelContrastRatio = getContrastRatio(textColor, backgroundColor);
	const fieldContrastRatio = getContrastRatio(fieldTextColor, fieldBackgroundColor);

	return labelContrastRatio >= 4.5 && fieldContrastRatio >= 4.5; // WCAG AA standard for normal text
};
