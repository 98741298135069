import axios from 'axios';
import { importedConfig } from 'config/config';
import { cloneDeep, debounce } from 'lodash';
import qs from 'query-string';
import {
	AllComponentTypes,
	childrenExcluder,
	ComponentDefaults,
	FieldComponentTypes,
	generateGuid,
	RowDefaultProperties,
	SlotDefaultProperties,
	validationMessages,
} from 'shared/src/utils/shared.js';

import { checkColorContrast } from '@/util/colorContrast';
import { ACCESSIBILITY_ERROR_CATEGORIES } from '@/util/resources';

import { claimsKeys, getAuthClient, getUser } from './auth/auth';
import {
	BrandingPalettes,
	BrowserColors,
	EditorTypes,
	ElementLabels,
	emptyDefaultJson,
	LimitedComponentTypes,
	MobilePropsToBeIgnored,
	MoosendPalette,
	PagePlaceholderProperties,
} from './resources';

export const getXMCUrl = (path) => {
	const envMode = import.meta.env.MODE;
	const user = getUser();
	const hostSuffix = ['preproduction', 'production'].includes(envMode) ? 'sitecorecloud.io' : 'sitecore-staging.cloud';

	return `https://xmc-${user[claimsKeys.TENANT_NAME]}.${hostSuffix}/${path}`;
};

//all metadata service keys are formed by contentID + IDExtension
// const getMetadataKey =  () => importedConfig.query.IDExtension ? importedConfig.query.contentID+'_'+importedConfig.query.IDExtension : importedConfig.query.contentID;
export const getMetadataKey = () => importedConfig.query.fullMetadataKey;

/**
 * This function returns true if the JSON.stringify of both objects is equal (string comparison).
 * @param obj1 - The first object to stringify and compare
 * @param obj2 - The second object to stringify and compare
 * @returns {boolean}
 */
export const simpleObjectEquals = (obj1, obj2) => {
	return JSON.stringify(obj1, childrenExcluder) === JSON.stringify(obj2, childrenExcluder); //second argument function is a callback to avoid circular references
};

/**
 * Compares arrays as sets. If the first array has extra elements they will be ignored. The second
 * array is considered the baseline for this comparison. This is normal because when you write tests,
 * you write expectedOutput by hand while the output may contain extra data that you don't mind
 * having in the result object.
 *
 * @param output
 * @param expectedOutput
 * @returns {*}
 */
export const areArraysEqualAsSets = (output, expectedOutput) => {
	return expectedOutput.every((expObj) => {
		return output.some((outObj) => {
			return simpleObjectEquals(expObj, outObj);
		});
	});
};

/**
 * Returns true if the two strings contain the same characters in any order
 * Example compareShuffledStrings('asd', 'sda') === true
 * Example compareShuffledStrings('asd', 'sdaa') === false
 *
 * @param str1 String
 * @param str2 String
 */
export const compareShuffledStrings = (str1, str2) => {
	if (str1.length !== str2.length) return false;

	let s1 = str1.split('');

	return s1.every((character) => {
		let regex = new RegExp(escapeRegExp(character), 'g');

		let match1Array = str1.match(regex);
		let match2Array = str2.match(regex);

		let match1 = match1Array && match1Array.length;
		let match2 = match2Array && match2Array.length;

		return match1 === match2;
	});
};

const escapeRegExp = (stringToGoIntoTheRegex) => {
	return stringToGoIntoTheRegex.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const findRow = (rows, id) => {
	const row = rows.find((r) => r.id === id);

	return {
		row,
		index: rows.indexOf(row),
	};
};

export const getRowPageData = (rows, id) => {
	const row = rows.find((r) => r.id === id);

	if (row) {
		return row.pageIndex;
	}

	return 0;
};

export const findRowByUniqueId = (rows, id) => {
	const row = rows.find((r) => r.uniqueId === id);

	if (!row) {
		throw new Error(`Row with unique id ${id} does not exist`);
	}

	return {
		row,
		index: rows.indexOf(row),
	};
};

/**
 * Sets attributes to DOM elements conveniently in React
 * @param attr - HTML attribute name
 * @param value - attribute value
 * @returns {Function} - a function that accepts a DOMNode or DOMElement parameter onto, which when executed sets an HTML attribute to the given value
 */
export const setAttributeInRef = (attr, value) => {
	return (node) => {
		if (node) {
			node.setAttribute(attr, value);
		}
	};
};

/**
 * Returns a function that takes 2 parameters like so var fn = createDelayedInterval(); fn(()=>{}, 1000).
 * The first parameter should be an anonymous function that executes your custom code after a delay.
 * The second parameter is the number of milliseconds after which your function will be executed.
 *
 * The main feature of this delayed interval is that if you call it repeatedly before the timer expires
 * , e.g. after each keystroke coming from the user's keyboard, then the timer will be reset to the same
 * value that was given to it initially, and your custom code will be called only after the timer has expired.
 *
 * @returns {Function}
 */
export const createDelayedInterval = () => {
	let delayedInterval;

	return (func, miliseconds) => {
		clearInterval(delayedInterval);

		delayedInterval = setInterval(() => {
			func();
			clearInterval(delayedInterval);
		}, miliseconds);
	};
};

/**
 * Takes an address object with the keys rowId, slot and component and returns a string representation of
 * the address numbers concatenated with no delimiters. Example for address = {rowId: 1, slot: 2, component: 0}
 * this function returns "120". Returns -1 for missing address values.
 * @param rowId
 * @param slot
 * @param component
 */
export const stringifyAddress = ({ rowId, slot, component } = { rowId: '-1', slot: -1, component: -1 }) => {
	return rowId + '' + slot + '' + component;
};

export const validateEmail = (email) => {
	let re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

/**
 * pathParams must be an object containing param names as keys and param values as values
 * example: for www.example.com/api/metadata/{key} api endpoint use
 * generateUrlWithPathParams('www.example.com/', 'api/metadata/{key}', {key: '12345'}) ==  www.example.com/api/metadata/12345
 *
 * @param url
 * @param path
 * @param pathParams
 * @returns {*}
 */
export const generateUrlWithPathParams = (url, path, pathParams = {}) => {
	let fullPath = url + path;

	Object.keys(pathParams).forEach((key) => {
		fullPath = fullPath.replace(`{${key}}`, pathParams[key]);
	});

	return fullPath;
};

/**
 * Returns the calculated height of an element whose width has been changed with its
 * aspect ratio locked based on its original width and height
 * @param originalWidth - original width used to calculate aspect ration
 * @param originalHeight - original height used to calculate aspect ration
 * @param currentWidth - resized width
 */
export const getResizedHeightFromWidth = (originalWidth, originalHeight, currentWidth) => {
	return (originalHeight * currentWidth) / originalWidth;
};

/**
 * Returns a DomElement ancestor of the DomElement passed as the first parameter which also has the class passed as the
 * second parameter. Similar to jQuery's $('.element').parents('.someParentClass');
 * @param el - a DomElement to start searching from
 * @param cls - a Class name to select the parent element with
 * @returns {DomElement} a DomElement ancestor of 'el' that contains 'cls'
 */
export const findAncestor = (el, cls) => {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
};

export function isValidUUID(string) {
	let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return regex.test(string);
}

//this returns a 0 if the input value is not a number and the number if not
export const getIntValue = (value) => {
	return isNaN(parseInt(value)) ? 0 : parseInt(value);
};

export const checkBoolean = (value) => {
	if (value === 'true' || value === true) {
		return true;
	}

	return false;
};

// leading/immediade debounce, function is executed immediately
export const leadingDebounce = (func, wait) => {
	return debounce(func, wait, { leading: true, trailing: false });
};

// trailing debounce, function is executed after wait time
export const trailingDebounce = (func, wait) => {
	return debounce(func, wait);
};

export const getSubId = (id) => {
	return id.toString().replace(/-/g, '').substr(0, 8);
};

//Calculate width of slot based on side and center spacings
export const calculateSlotWidth = (slotWidth, slotsLength, centerSpacing, sideSpacing) => {
	if (isNaN(centerSpacing)) {
		centerSpacing = 0;
	}

	if (isNaN(sideSpacing)) {
		sideSpacing = 0;
	}

	let centerSpacingTotal = centerSpacing * (slotsLength - 1);

	let sideSpacingTotal = sideSpacing * 2;

	let totalSpacing = centerSpacingTotal + sideSpacingTotal;

	return Math.round(slotWidth - totalSpacing / slotsLength);
};

export const trunc = (string, n) => {
	return string && string.length > n ? string.substr(0, n - 1) + '…' : string;
};

//example string "hello {world}", word inside {} will be replaced with a value from the second parameter
export const interpolateString = (str, keys) => {
	let fullStr = str;

	if (keys) {
		Object.keys(keys).forEach((key) => {
			if (keys && keys[key]) fullStr = fullStr.replace(`{${key}}`, keys[key]);
		});
	}

	return fullStr;
};

export const extractRealRowProps = (row) => {
	let realRow = {};
	let realRowKeys = Object.keys(RowDefaultProperties);

	realRow.id = row.id;
	realRow.type = row.type;
	realRow.slots = cloneDeep(row.slots);

	Object.keys(row).forEach((key) => {
		if (realRowKeys.includes(key) && realRowKeys.includes(key)) {
			realRow[key] = row[key];
		}
	});

	return { ...RowDefaultProperties, ...realRow };
};

export const extractRealElementProps = (element) => {
	let realElement = {};
	let realElementKeys = Object.keys(ComponentDefaults[element.type]);

	realElement.id = element.id;
	realElement.type = element.type;

	Object.keys(element).forEach((key) => {
		if (realElementKeys.includes(key) && realElementKeys.includes(key)) {
			realElement[key] = element[key];
		}
	});

	return realElement;
};

export const fireEvent = (element, event) => {
	var evt;

	evt = document.createEvent('HTMLEvents');
	evt.initEvent(event, true, true); // event type,bubbling,cancelable
	return !element.dispatchEvent(evt);
};

export const getHostname = (url) => {
	var parser = document.createElement('a');
	parser.href = url;

	return parser.hostname;
};

export const getEditorType = () => {
	const search = qs.parse(location.search);
	return search && search.editorType ? search.editorType : EditorTypes.inline;
};

export const findElementIndexById = (elementArray, elementId) => {
	for (let i = 0; i < elementArray.length; i++) {
		if (elementArray[i].id === elementId) {
			return i;
		}
	}
	return -1;
};

export const findElementAddressById = (rows, id) => {
	let address;
	for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
		if (address) {
			break;
		}
		const slots = rows[rowIndex].slots;
		for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
			if (address) {
				break;
			}
			const components = slots[slotIndex].components;
			for (let componentIndex = 0; componentIndex < components.length; componentIndex++) {
				if (address) {
					break;
				}
				if (components[componentIndex].id === id) {
					address = { rowId: rows[rowIndex].id, slot: slotIndex, component: componentIndex };
				}
			}
		}
	}
	return address;
};

export const getItemAndIndexById = (itemArray, itemId) => {
	for (let i = 0; i < itemArray.length; i++) {
		if (itemArray[i].id === itemId) {
			return { item: itemArray[i], index: i };
		}
	}
	return null;
};

export const checkQuertStringProperty = (key) => {
	return key in qs.parse(location.search);
};

export const isTemplateMgm = () => {
	return (
		!checkQuertStringProperty('contentID') &&
		!checkQuertStringProperty('entityId') &&
		!checkQuertStringProperty('genericId') &&
		checkQuertStringProperty('secondaryRedirectUrl')
	);
};

export const pathPrefix = () => '/design';

// export const processHtml = (str) => {

//     const div = document.createElement('div');
//     div.innerHTML = str.trim();

//     return formatHtml(div, 0).innerHTML;
// }

// const formatHtml = (node: HTMLElement, level) => {

//     let indentBefore = new Array(level++ + 1).join('  '),
//         indentAfter  = new Array(level - 1).join('  '),
//         textNode;

//     for (let i = 0; i < node.children.length; i++) {

//         textNode = document.createTextNode('\n' + indentBefore);
//         node.insertBefore(textNode, node.children[i]);

//         formatHtml(node.children[i], level);

//         if (node.lastElementChild == node.children[i]) {
//             textNode = document.createTextNode('\n' + indentAfter);
//             node.appendChild(textNode);
//         }
//     }

//     return node;
// }

export const hasBlueprints = () => {
	return importedConfig.query.hasBlueprints;
};

export const makeApiGatewayCall = async (url, method, payload, extraHeaders, requestConfig) => {
	const authClient = await getAuthClient();
	const token = await authClient.getTokenSilently();

	const conf = {
		headers: {
			Authorization: `Bearer ${token}`,
			...extraHeaders,
		},
		...requestConfig,
	};

	switch (method.toLowerCase()) {
		case 'get':
			return axios.get(url, conf);
		case 'post':
			return axios.post(url, payload, conf);
		case 'put':
			return axios.put(url, payload, conf);
		case 'delete':
			return axios.delete(url, conf);
		case 'patch':
			return axios.patch(url, payload, conf);
		default:
			return Promise.reject('Method not supported');
	}
};

export const generateGoogleFontsUrl = (fonts) => {
	let embed = '';
	fonts.map((item, i) => {
		embed = `${embed}${item.embed}${i === fonts.length - 1 ? '' : '|'}`;
	});
	return `https://fonts.googleapis.com/css?family=${embed}&amp;subset=greek,greek-ext`;
};

export const addFontToStylesheets = (fonts) => {
	const link = document.getElementById('google-web-fonts-stylesheets');
	const url = generateGoogleFontsUrl(fonts);

	if (link) {
		if (link.href === url) return;
		link.parentElement.removeChild(link);
	}

	const newLink = document.createElement('link');
	newLink.id = 'google-web-fonts-stylesheets';
	newLink.rel = 'stylesheet';
	newLink.href = url;
	document.head.append(newLink);
};

export const sortFonts = (fonts) => {
	return fonts.split(';').sort().join(';');
};

export const compareFontLabels = (a, b) => {
	const labelA = a.label.toUpperCase();
	const labelB = b.label.toUpperCase();

	let comparison = 0;

	if (labelA < labelB) {
		comparison = 1;
	} else if (labelA > labelB) {
		comparison = -1;
	}

	return comparison * -1;
};

export const validYoutTubeUrl = (url) => {
	const regEx = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
	return url.match(regEx) ? true : false;
};

export const validVimeoUrl = (url) => {
	const regEx = /https:\/\/vimeo.com\/\d{8,9}(?=\b|\/)/;
	return url.match(regEx) ? true : false;
};

export const validAnyUrl = (url) => {
	const regEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
	return url.match(regEx) ? true : false;
};

export const youtubeThumbnail = (id) => `https://img.youtube.com/vi/${id}/0.jpg`;

export const vimeoThumbnail = (id) => `https://vimeo.com/api/v2/video/${id}.json`;

export const getFormFieldFontStyles = (font) => {
	if (font.includes(':')) {
		const fontArray = font.split(':');

		let returnFont = {
			fontFamily: fontArray[0],
		};

		if (fontArray[1] === 'italic') {
			returnFont = {
				...returnFont,
				fontStyle: fontArray[1],
			};
		} else if (fontArray[1].length === 3) {
			returnFont = {
				...returnFont,
				fontWeight: fontArray[1],
			};
		} else if (fontArray[1].includes('italic') && fontArray[1].length > 3) {
			returnFont = {
				...returnFont,
				fontWeight: fontArray[1].substr(0, 3),
				fontStyle: fontArray[1].substr(3, fontArray[1].length - 1),
			};
		}

		return returnFont;
	}
	return {
		fontFamily: font,
	};
};

export const updateTemplateJson = (json, mobileJson, generateIds = false) => {
	const rows = json.rows;

	const compatJson = { ...json, validation_messages: { ...validationMessages, ...json.validation_messages } };

	if (mobileJson) {
		let mobileProps = {};
		Object.keys(mobileJson).forEach((key) => {
			if (!MobilePropsToBeIgnored.includes(key) && JSON.stringify(mobileJson[key]) !== JSON.stringify(compatJson[key])) {
				mobileProps = { ...mobileProps, [key]: mobileJson[key] };
			}
		});
		mobileProps = { ...mobileProps, ...compatJson.mobileProps };
		compatJson.mobileProps = mobileProps;
	}

	const newRows = [];
	rows.forEach((row, rowIndex) => {
		if (row) {
			const slots = row.slots;

			const newSlots = [];

			row.mobileProps = { index: rowIndex, ...row.mobileProps, responsive: row.responsive };

			// LEGACY
			const mobileRow = mobileJson ? getItemAndIndexById(mobileJson.rows, row.id) : null;

			if (mobileRow && mobileRow.item) {
				let mobileProps = {};
				Object.keys(mobileRow.item).forEach((key) => {
					if (!MobilePropsToBeIgnored.includes(key) && JSON.stringify(mobileRow.item[key]) !== JSON.stringify(row[key])) {
						mobileProps = { ...mobileProps, [key]: mobileRow.item[key] };
					}
				});
				mobileProps = { ...mobileProps, index: mobileRow.index, mobileChanged: true };
				row.mobileProps = { ...row.mobileProps, ...mobileProps };
			}

			slots.forEach((slot, slotIndex) => {
				if (slot) {
					const components = slot.components;

					const newComponents = [];

					slot.mobileProps = { index: slotIndex, ...slot.mobileProps };

					// LEGACY
					const mobileSlot = mobileJson ? getItemAndIndexById(mobileRow.item.slots, slot.id) : null;
					if (mobileSlot && mobileSlot.item) {
						let mobileProps = {};
						Object.keys(mobileSlot.item).forEach((key) => {
							if (!MobilePropsToBeIgnored.includes(key) && JSON.stringify(mobileSlot.item[key]) !== JSON.stringify(slot[key])) {
								mobileProps = { ...mobileProps, [key]: mobileSlot.item[key], mobileChanged: true };
							}
						});
						mobileProps = { ...mobileProps, index: mobileSlot.index };
						slot.mobileProps = { ...slot.mobileProps, ...mobileProps };
					}

					components.forEach((component, componentIndex) => {
						component.mobileProps = { index: componentIndex, ...component.mobileProps };

						if (component.options && component.options.length && typeof component.hasCustomValues === 'undefined') {
							component.options = component.options.map((item) => ({ value: item.value, label: item.value }));
						}

						// LEGACY
						const mobileComponent = mobileJson ? getItemAndIndexById(mobileSlot.item.components, component.id) : null;
						if (mobileComponent && mobileComponent.item) {
							let mobileProps = {};
							Object.keys(mobileComponent.item).forEach((key) => {
								if (!MobilePropsToBeIgnored.includes(key) && JSON.stringify(mobileComponent.item[key]) !== JSON.stringify(component[key])) {
									mobileProps = { ...mobileProps, [key]: mobileComponent.item[key], mobileChanged: true };
								}
							});
							mobileProps = { ...mobileProps, index: mobileComponent.index };
							component.mobileProps = { ...component.mobileProps, ...mobileProps };
						}

						const newComponentId = generateIds ? generateGuid() : component.id;
						if (component && component.type) {
							const fixedComponent = fixComponentJson(component);
							const additionalProps =
								component.type === AllComponentTypes.submit_button
									? { back_button: { ...ComponentDefaults[component.type].back_button, ...fixedComponent.back_button } }
									: {};
							newComponents.push({
								...ComponentDefaults[component.type],
								...fixedComponent,
								mobileProps: { ...fixedComponent.mobileProps, id: newComponentId },
								...additionalProps,
								id: newComponentId,
								uniqueId: component.uniqueId ? component.uniqueId : generateGuid(),
							});
						}
					});

					const newSlotId = generateIds ? generateGuid() : slot.id;
					newSlots.push({
						...SlotDefaultProperties,
						...slot,
						mobileProps: { ...slot.mobileProps, id: newSlotId },
						components: newComponents,
						id: newSlotId,
						uniqueId: slot.uniqueId ? slot.uniqueId : generateGuid(),
					});
				}
			});

			const rowSlotSpacings = {
				slot_spacing_side: 0,
				slot_spacing_center: 0,
			};

			const newRowId = generateIds ? generateGuid() : row.id;

			const newRow = {
				...RowDefaultProperties,
				...row,
				mobileProps: { ...row.mobileProps, id: newRowId },
				...rowSlotSpacings,
				pageIndex: typeof row.pageIndex !== 'undefined' ? row.pageIndex : 0,
				slots: newSlots,
				id: newRowId,
				uniqueId: row.uniqueId ? row.uniqueId : generateGuid(),
			};

			newRows.push(newRow);
		}
	});

	return {
		...emptyDefaultJson[getEditorType()],
		...compatJson,
		lastPage: typeof compatJson.lastPage !== 'undefined' ? compatJson.lastPage : getLastPage(newRows).pageIndex,
		lastPageAll: typeof compatJson.lastPageAll !== 'undefined' ? compatJson.lastPageAll : getLastPageForAll(newRows).pageIndex,
		rows: newRows,
	};
};

export const getLastPage = (rows) => {
	if (rows && rows.length) {
		return rows
			.filter((item) => item.type !== PagePlaceholderProperties.type)
			.reduce((max, current) => {
				return current.pageIndex > max.pageIndex ? current : max;
			}, rows[0]);
	}
	return { pageIndex: 0 };
};

export const getLastPageForAll = (rows) => {
	if (rows && rows.length) {
		return rows.reduce((max, current) => {
			return current.pageIndex > max.pageIndex ? current : max;
		}, rows[0]);
	}
	return { pageIndex: 0 };
};

export const updateComments = (rows, comments) => {
	let newComments = [];

	rows.forEach((row) => {
		comments.forEach((item) => {
			if (item.targetId === row.uniqueId) {
				newComments.push(item);
			}
		});

		const slots = row.slots;

		slots.forEach((slot) => {
			const components = slot.components;

			components.forEach((component) => {
				comments.forEach((item) => {
					if (item.targetId === component.uniqueId) {
						newComments.push(item);
					}
				});
			});
		});
	});

	return newComments;
};

export const fixComponentJson = (component) => {
	let newComponent = { ...component, newComponent: false };

	switch (component.type) {
		case AllComponentTypes.image:
			if (component.originalSrc === 'https://cdn.designer-images.net/yannis_161219144525123.png') {
				newComponent = {
					...newComponent,
					resizeWidth: null,
					resized: false,
				};
			}

			if (component.resizeHeight === 0 || component.resizeWidth === 0) {
				newComponent = {
					...newComponent,
					resizeHeight: null,
					resizeWidth: null,
					resized: false,
				};
			}
			break;

		default:
			break;
	}

	return newComponent;
};

export const checkTemplateCompatibility = (templateJson, mailingLists) => {
	if (!mailingLists.length) {
		const rows = templateJson.json.rows;
		let formFound = false;

		for (let i = 0; i < rows.length; i++) {
			if (formFound) {
				break;
			}
			const slots = rows[i].slots;
			for (let j = 0; j < slots.length; j++) {
				if (formFound) {
					break;
				}
				const components = slots[j].components;
				for (let k = 0; k < components.length; k++) {
					if (formFound) {
						break;
					}
					if (components[k].type === AllComponentTypes.form) {
						formFound = true;
					}
				}
			}
		}

		if (formFound) {
			return false;
		}
	}

	return true;
};

export const transformBlockTitle = (title) => {
	return title.replace(/ /g, '').replace(/\//g, '').replace(/-/g, '').replace('1323', 'Third');
};

export const rgbToHex = (color) => {
	if (color) {
		if (color.substr(0, 1) === '#' || color === 'transparent' || BrowserColors.indexOf(color.toLowerCase()) !== -1) {
			return color.toLowerCase();
		} else if (color.indexOf('rgb(') === 0) {
			color = color.replace(/\s/g, '');
			var digits = /(.*?)rgb\((\d+),(\d+),(\d+)\)/.exec(color);

			var r = parseInt(digits[2]);
			var g = parseInt(digits[3]);
			var b = parseInt(digits[4]);

			return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
		}
	}
	return 'transparent';
};

export const getPaletteById = (paletteId) => {
	if (paletteId) {
		const palette = BrandingPalettes.find((item) => {
			return item.id === paletteId;
		});

		if (palette && palette.palette) {
			return palette.palette;
		}
		return MoosendPalette.palette;
	}
	return MoosendPalette.palette;
};

export const formatTestId = (str) => {
	return str ? str.toLowerCase().replace(/ /g, '-') : '';
};

export const copyToClipboard = (text, callback) => {
	if (text) {
		navigator.clipboard.writeText(text);
		if (callback) callback();
	}
};

export const getDuplicateFieldNames = (content) => {
	const fieldNames = [];

	content.rows.forEach((row) => {
		row.slots.forEach((slot) => {
			slot.components.forEach((component) => {
				if (
					Object.values(FieldComponentTypes)
						.filter((item) => item !== AllComponentTypes.submit_button)
						.includes(component.type) &&
					component.name
				) {
					fieldNames.push(component.name);
				}
			});
		});
	});

	const duplicateFieldNames = fieldNames.filter((item, index) => fieldNames.indexOf(item) != index);

	return duplicateFieldNames;
};

export const checkJsonValidity = (rows, lastPage = 0, isMobileView = false) => {
	const elementTypesForLabel = Object.fromEntries(Object.entries(FieldComponentTypes).filter((e) => e[0] !== 'recaptcha'));
	const accessibilityErrors = [];
	const limitedComponents = {};
	const errors = {};
	let fieldNames = [];
	const newRows = [];
	const allFields = [];

	if (!rows.length) {
		return { validity: { limitedComponents, errors, hasErrors: false, errorsCount: 0 }, rows, allFields, accessibilityErrors };
	}

	// Fix mobile indexes
	const sortedRows = [...rows].sort((a, b) => a.mobileProps.index - b.mobileProps.index);

	for (let r = 0; r < sortedRows.length; r++) {
		if (sortedRows[r - 1]) {
			if (sortedRows[r - 1].mobileProps.index === sortedRows[r].mobileProps.index) {
				const newIndex = sortedRows[r].mobileProps.index + 1;
				sortedRows[r] = { ...sortedRows[r], mobileProps: { ...sortedRows[r].mobileProps, index: newIndex } };
			}
		}
	}

	const pages = [...Array(lastPage + 1).keys()];
	pages.forEach((item) => {
		limitedComponents[item] = [];
		errors[item] = [
			{
				pageIndex: item,
				leftMessage: 'An',
				anchor: 'action button',
				rightMessage: 'is missing from the page.',
				title: 'An action button is missing from the page.',
				type: AllComponentTypes.submit_button,
			},
		];
	});

	for (let r = 0; r < rows.length; r++) {
		const row = rows[r];
		const slots = row.slots;

		// Insert with new mobile indexes
		newRows.push(sortedRows.find((item) => item.id === row.id));

		for (let s = 0; s < slots.length; s++) {
			const slot = slots[s];
			const components = slot.components;
			for (let c = 0; c < slot.components.length; c++) {
				const component = isMobileView ? { ...components[c], ...components[c].mobileProps } : components[c];
				const address = {
					rowId: row.id,
					slot: s,
					component: c,
				};

				// Accessibility checks
				if (component.type === AllComponentTypes.image && !component.alt) {
					accessibilityErrors.push({
						page: row.pageIndex,
						type: component.type,
						id: component.id,
						errorType: ACCESSIBILITY_ERROR_CATEGORIES.ALT_TEXT,
						address,
					});
				}

				if (
					elementTypesForLabel[component.type] &&
					(component.type === 'submit_button' ? !component.text : !component.label || component.labelHide)
				) {
					accessibilityErrors.push({
						page: row.pageIndex,
						type: component.type,
						id: component.id,
						errorType: ACCESSIBILITY_ERROR_CATEGORIES.LABEL,
						address,
					});
				}

				// if (component.type === AllComponentTypes.link && !component.text) {
				// 	accessibilityErrors.push({
				// 		page: row.pageIndex,
				// 		type: component.type,
				// 		id: component.id,
				// 		errorType: ACCESSIBILITY_ERROR_CATEGORIES.DESCRIPTIVE_TEXT,
				// 	});
				// }

				if (
					component.type !== FieldComponentTypes.recaptcha &&
					component.type !== FieldComponentTypes.gdpr &&
					(FieldComponentTypes[component.type] || component.type === 'text') &&
					!checkColorContrast(component)
				) {
					accessibilityErrors.push({
						page: row.pageIndex,
						type: component.type,
						id: component.id,
						errorType: ACCESSIBILITY_ERROR_CATEGORIES.CONTRAST,
						address,
					});
				}

				if (
					component.type !== FieldComponentTypes.recaptcha &&
					component.type !== FieldComponentTypes.submit_button &&
					component.type !== FieldComponentTypes.gdpr &&
					FieldComponentTypes[component.type]
				) {
					allFields.push({
						id: component.uniqueId,
						name: component.name,
						label: component.label,
						type: component.type,
						page: row.pageIndex,
						options: component.options,
						required: component.required,
					});
				}

				if (
					LimitedComponentTypes.includes(component.type) &&
					!limitedComponents[row.pageIndex].find((item) => item.type === component.type)
				) {
					switch (component.type) {
						case AllComponentTypes.recaptcha:
							pages.forEach((item) => {
								limitedComponents[item].push({
									type: component.type,
									pageIndex: item,
									id: component.id,
								});
							});

							if (row.pageIndex !== lastPage) {
								errors[row.pageIndex].push({
									anchor: 'reCAPTCHA field',
									rightMessage: 'should always be on the last page.',
									title: 'reCAPTCHA field should always be on the last page.',
									pageIndex: row.pageIndex,
									type: component.type,
								});
							}
							break;

						case AllComponentTypes.gdpr:
							pages.forEach((item) => {
								limitedComponents[item].push({
									type: component.type,
									pageIndex: item,
									id: component.id,
								});
							});
							break;

						case AllComponentTypes.submit_button:
							limitedComponents[row.pageIndex].push({
								type: component.type,
								pageIndex: row.pageIndex,
								id: component.id,
							});

							errors[row.pageIndex] = errors[row.pageIndex].filter((item) => item.type !== component.type);

							break;

						default:
							limitedComponents[row.pageIndex].push({
								type: component.type,
								pageIndex: row.pageIndex,
								id: component.id,
							});
							break;
					}
				}

				if (
					Object.keys(FieldComponentTypes)
						.filter((item) => item !== AllComponentTypes.submit_button)
						.includes(component.type)
				) {
					const duplicateName = fieldNames.find((item) => component.name && item.name === component.name);
					if (duplicateName) {
						if (fieldNames.length === 1) {
							errors[duplicateName.pageIndex].push({
								title: `${ElementLabels[duplicateName.type]} field name is duplicate.`,
								anchor: `${ElementLabels[duplicateName.type]} field`,
								rightMessage: 'name is duplicate.',
								pageIndex: duplicateName.pageIndex,
								type: duplicateName.type,
								address: duplicateName.address,
							});
						}
						errors[row.pageIndex].push({
							title: `${ElementLabels[component.type]} field name is duplicate.`,
							anchor: `${ElementLabels[component.type]} field`,
							rightMessage: 'name is duplicate.',
							pageIndex: row.pageIndex,
							type: component.type,
							address: { rowId: row.id, slot: s, component: c },
						});
					}

					if (component.name) {
						fieldNames = [
							...fieldNames,
							{
								address: { rowId: row.id, slot: s, component: c },
								name: component.name,
								pageIndex: row.pageIndex,
								type: component.type,
							},
						];
					}
				}
			}
		}
	}

	let flatErrors = [];

	Object.values(errors).forEach((item) => {
		flatErrors = [...flatErrors, ...item];
	});

	return {
		validity: {
			limitedComponents,
			errors,
			hasErrors: flatErrors.length ? true : false,
			errorsCount: flatErrors.length,
		},
		accessibilityErrors,
		rows: newRows,
		allFields,
	};
};

export const hasLimitComps = (limitedComponents, pageIndex) => {
	return limitedComponents[pageIndex] && limitedComponents[pageIndex].length;
};

export const truncateString = (str, charsToTruncate) => {
	return str.length > charsToTruncate ? str.substr(0, charsToTruncate - 1) + '…' : str;
};

export const hasLimitedComponents = (row, limitedComponents) => {
	let hasLimitedComponents = false;
	const slots = row.slots;
	for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
		if (hasLimitedComponents) {
			break;
		}
		const components = slots[slotIndex].components;
		for (let componentIndex = 0; componentIndex < components.length; componentIndex++) {
			if (hasLimitedComponents) {
				break;
			}
			if (limitedComponents && limitedComponents.find((item) => item.type === components[componentIndex].type)) hasLimitedComponents = true;
		}
	}

	return hasLimitedComponents;
};

export const scrollToElementById = (id) => {
	const element = document.querySelectorAll(`[data-flip-id="${id}"]`)?.[0];

	if (element) {
		element.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}
};
