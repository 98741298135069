import config from 'config/config';
import Moment from 'moment';
import { generateGuid, getEntityId } from 'shared/src/utils/shared.js';
import { create } from 'zustand';

import { getComments, notifyMembers, saveComment, updateComment } from '@/util/apiUtils/template';
import { claimsKeys, getUser } from '@/util/auth/auth';
import { getEditorType, updateComments } from '@/util/helper';

import useContentStore from './ContentStore';
import { showNotification } from './NotificationStore';

const useCommentStore = create((set, get) => ({
	teamMembers: [],
	comments: [],
	unreadComments: 0,
	replies: [],
	flatComments: [],
	showResolvedComments: false,
	saveComment: async (details) => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;
			const teamMemberId = userData.sub;

			const key = entityId || teamId;

			let newComments = [];

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			if (all && all.length) {
				all.forEach((item) => {
					newComments.push({ ...item, replies: [] });
				});
			}

			const id = generateGuid();

			newComments.push({
				...details,
				seenBy: [teamMemberId],
				id,
				date: Moment().format(),
				user: userData.name,
				teamMemberId,
			});

			await saveComment(key, newComments);

			get().getComments();

			get().notifyMembers(details, comments, id);
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to save comment' });
			console.error(e);
		}
	},
	editComment: async (details) => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;

			const key = entityId || teamId;

			let newComments = [];

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			const editComment = all.find((item) => {
				return item.id === details.id;
			});

			if (editComment) {
				if (all && all.length) {
					all.forEach((item) => {
						if (item.id !== editComment.id) {
							newComments.push({ ...item, replies: [] });
						}
					});
				}

				delete details.replies;
				newComments.push(details);

				await saveComment(key, newComments);

				get().getComments();

				if (editComment.status !== details.status && details.status === 'resolved') {
					get().resolveNotifyMembers(editComment);
				} else if (
					(editComment.status !== details.status && details.status === 'unresolved') ||
					JSON.stringify(editComment.seenBy) !== JSON.stringify(details.seenBy)
				) {
					return;
				} else {
					get().notifyMembers(details, comments, details.id);
				}
			}
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to save comment' });
			console.error(e);
		}
	},
	deleteComment: async (id) => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;

			const key = entityId || teamId;

			let newComments = [];

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			if (all && all.length) {
				all.forEach((item) => {
					if (item.id !== id && item.parentId !== id) {
						newComments.push({ ...item, replies: [] });
					}
				});
			}

			await saveComment(key, newComments);

			showNotification({ type: 'SUCCESS', text: 'Comment deleted' });

			get().getComments();
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to save comment' });
			console.error(e);
		}
	},
	deleteAllComments: async () => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;

			const key = entityId || teamId;

			let newComments = [];

			await saveComment(key, newComments);

			get().getComments();
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to delete comments' });
			console.error(e);
		}
	},
	updateComments: async () => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;

			const key = entityId || teamId;

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			// TODO: Fix after contents store is migrated
			const rows = useContentStore.getState().content.rows;

			const newComments = updateComments(rows, all);

			await updateComment(key, newComments);
			get().getComments();
		} catch (e) {
			console.error(e);
		}
	},
	markCommentsAsSeen: async (targetId) => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;
			const teamMemberId = userData.sub;
			const key = entityId || teamId;

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			let shouldUpdate = false;

			const updatedComments = all.map((item) => {
				if (item.targetId === targetId && !item.seenBy.includes(teamMemberId)) {
					shouldUpdate = true;
					return { ...item, replies: [], seenBy: [...item.seenBy, teamMemberId] };
				}
				return { ...item, replies: [] };
			});

			if (shouldUpdate) {
				await saveComment(key, updatedComments);
				get().getComments();
			}
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to update comments' });
			console.error(e);
		}
	},
	markAllCommentsAsSeen: async () => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;
			const teamMemberId = userData.sub;

			const key = entityId || teamId;

			const comments = get().comments.map((item) => {
				return { ...item, replies: [] };
			});
			const replies = get().replies;
			const all = [...comments, ...replies];

			const updatedComments = all.map((item) => {
				const seenBy = item.seenBy.includes(teamMemberId) ? [...item.seenBy] : [...item.seenBy, teamMemberId];
				return { ...item, replies: [], seenBy };
			});

			await saveComment(key, updatedComments);

			get().getComments();
		} catch (e) {
			showNotification({ type: 'ERROR', text: 'Failed to mark comments as read' });
			console.error(e);
		}
	},
	getComments: async () => {
		try {
			const entityId = getEntityId(config);
			const userData = getUser();
			const teamId = `${getEditorType()}-${userData[claimsKeys.TENANT_ID]}`;

			const key = entityId || teamId;

			const getCommentsRsp = await getComments(key);

			if (getCommentsRsp && getCommentsRsp.data) {
				try {
					const data = JSON.parse(getCommentsRsp.data.Content.Data);
					get().setComments(data);
				} catch (e) {
					console.error(e);
				}
			}
		} catch (e) {
			console.error(e);
		}
	},
	setComments: (payload) => {
		const commentsMetadata = payload.comments;
		const userData = getUser();
		const teamMemberId = userData.sub;
		let unreadComments = 0;
		const replies =
			commentsMetadata.filter((item) => {
				return item.parentId;
			}) || [];

		const comments =
			commentsMetadata
				.filter((item) => {
					return !item.parentId;
				})
				.sort((a, b) => {
					return Moment(b.date).diff(a.date);
				}) || [];

		comments.forEach((item) => {
			if (!item.seenBy.includes(teamMemberId)) {
				unreadComments = unreadComments + 1;
			}
		});

		replies.forEach((item) => {
			let index = -1;
			const parent = comments.find((comment, i) => {
				index = i;
				return comment.id === item.parentId;
			});
			if (parent) {
				comments.splice(index, 1, {
					...comments[index],
					replies: comments[index].replies ? [...comments[index].replies, item] : [item],
				});
			}
		});

		return set(() => ({
			comments,
			replies,
			flatComments: commentsMetadata,
			unreadComments,
		}));
	},
	notifyMembers: async (details, comments, commentId) => {
		try {
			const notifications = [];
			const userData = getUser();

			const currentTeamMemberId = userData.sub;

			const mentions = details.content.mentions;
			const parentComment = details.parentId ? comments.find((item) => item.id === details.parentId) : null;

			const locationSearch = location.search ? location.search + `&commentId=${commentId}` : `?commentId=${commentId}`;
			const redirectUrl = location.pathname.replace('/', '') + locationSearch;

			if (parentComment) {
				notifications.push({
					SenderTeamMemberId: currentTeamMemberId,
					TeamMemberIds: [parentComment.teamMemberId],
					// Text: parentComment.content.valuePlain,
					Text: details.content.valuePlain,
					Template: 'Parent',
					RedirectUrl: redirectUrl,
				});
			}

			if (mentions && mentions.length) {
				notifications.push({
					SenderTeamMemberId: currentTeamMemberId,
					TeamMemberIds: mentions.map((mention) => mention.id),
					Text: details.content.valuePlain,
					Template: 'Mention',
					RedirectUrl: redirectUrl,
				});
			}

			if (notifications.length) {
				await notifyMembers({ notifications });
			}
		} catch (e) {
			console.error(e);
		}
	},
	resolveNotifyMembers: async (editComment) => {
		try {
			const notifications = [];
			const userData = getUser();

			const currentTeamMemberId = userData.sub;
			const redirectUrl = location.pathname.replace('/', '') + location.search;

			let teamMemberIds = editComment.teamMemberId !== currentTeamMemberId ? [editComment.teamMemberId] : [];

			if (editComment.content.mentions && editComment.content.mentions.length) {
				editComment.content.mentions.forEach((mention) => {
					if (mention.id !== currentTeamMemberId && !teamMemberIds.includes(mention.id)) {
						teamMemberIds = [...teamMemberIds, mention.id];
					}
				});
			}

			if (editComment && editComment.replies && editComment.replies.length) {
				editComment.replies.forEach((item) => {
					if (item.content.mentions && item.content.mentions.length) {
						item.content.mentions.forEach((mention) => {
							if (mention.id !== currentTeamMemberId && !teamMemberIds.includes(mention.id)) {
								teamMemberIds = [...teamMemberIds, mention.id];
							}
						});
					}
				});
			}

			if (teamMemberIds.length) {
				notifications.push({
					TeamMemberIds: teamMemberIds,
					Text: editComment.content.valuePlain,
					SenderTeamMemberId: currentTeamMemberId,
					Template: 'Resolve',
					RedirectUrl: redirectUrl,
				});
			}

			if (notifications.length) {
				await notifyMembers({ notifications });
			}
		} catch (e) {
			console.error(e);
		}
	},

	setShowResolvedComments: (showResolvedComments) => set(() => ({ showResolvedComments })),
	setData: (data) => set(() => ({ ...data })),
}));

export default useCommentStore;

// TODO: UPDATE COMMENTS ON NEWSLETTER ACTIONS
// this.on('beforeEach', ({ payload, state }) => {
// 	if (payload && payload.details) {
// 		let { name, namespace } = payload.details;

// 		if (['removeRow', 'removeComponent', 'loadAndSetTemplate', 'setDraftAsContentJson', 'setDraftAsContent'].includes(name)) {
// 			this.delayedInterval(() => {
// 				CommentActions.updateComments();
// 			}, 2000);
// 		}
// 	}
// });
