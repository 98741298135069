import React from 'react';

import { Box } from '@chakra-ui/react';
import { AllComponentTypes } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';

import { ElementLimits } from '@/util/resources';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';

export const SpacerSettingsFields = {
	height: 'height',
	borderWidth: 'borderWidth',
	borderRadius: 'borderRadius',
	borderColor: 'borderColor',
};

export const SpacerSettingComponents = React.memo((props) => {
	return (
		<SettingsBox blockId={props.blockId}>
			<NumberSetting
				type={SpacerSettingsFields.height}
				inputType="number"
				onChange={props.onChange}
				text={props.height || ElementLimits[AllComponentTypes.spacer].height.min}
				label="Height"
				ariaLabel="Set height"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderWidth}
				onChange={props.onChange}
				text={props.borderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type={SpacerSettingsFields.borderColor}
				onChange={props.onChange}
				selectedColor={props.borderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderRadius}
				onChange={props.onChange}
				text={props.borderRadius}
				label="Border radius"
				ariaLabel="Border radius in pixels"
			/>
		</SettingsBox>
	);
});

const SpacerSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		const settings = {
			[data.type]: data.value,
		};

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<Box className="spacer-settings">
			<SpacerSettingComponents {...props} onChange={onChange} />
		</Box>
	);
};

export default SpacerSettings;
