import React from 'react';

import { Box, Divider, Flex, Heading, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { mdiChevronUp, mdiFileDocumentOutline, mdiInformationOutline, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { getPadding } from 'shared/src/utils/shared.js';
import { useShallow } from 'zustand/react/shallow';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { getFormFieldFontStyles } from '@/util/helper';

import { Icon } from '@/components/gui/shared/Icon';

import { Button } from './Button.react';

const activeStyles = {
	outline: '3px solid var(--colors-purple-400)',
	outlineOffset: '-3px',
};

const exampleData = {
	phone_input: '+1 (201) 555-0123',
	email_input: 'example@email.com',
	text_input: 'Example text',
	longtext_input: 'This is an example text',
	date_input: '01/01/2025',
	number_input: '10',
	singleselect_input: 'Option 1',
	multiselect_input: 'Option 1, Option 2',
	checkbox_group: 'Option 1, Option 2',
	radio: 'Option 1',
	checkbox: 'Yes',
};

export const SummaryPage = ({ editingState, isDraggingRow }) => {
	const { toggleSummaryPage, allFields, summaryPage, structureWidth, fontFamily, retinaImages, setClickedSummaryPage } = useContentStore(
		useShallow((state) => ({
			toggleSummaryPage: state.toggleSummaryPage,
			setClickedSummaryPage: state.setClickedSummaryPage,
			allFields: state.allFields,
			summaryPage: state.content.summaryPage,
			structureWidth: state.content.structureWidth,
			fontFamily: state.content.font_family,
			retinaImages: state.content.settings?.retina_images,
		})),
	);

	const { active, toggleSummaryPageSettings } = useLeftSidebarStore(
		useShallow((state) => ({
			active: state.settings.toggledElementSettingsAddress === 'summary_page',
			toggleSummaryPageSettings: state.toggleSummaryPageSettings,
		})),
	);

	if (!summaryPage.active || !editingState.rows || editingState.rows.length === 0 || (editingState.rows.length === 1 && isDraggingRow))
		return null;

	const {
		padding,
		multiPadding,
		borderWidth,
		borderColor,
		borderRadius,
		background_image,
		background_repeat,
		background_position,
		bg_color,
		width,
	} = summaryPage.layoutStyles;

	const border = borderWidth ? `${borderWidth}px solid ${borderColor}` : '';
	const bgSize = width || structureWidth;

	return (
		<Box
			px={6}
			py={3}
			borderTopWidth={1}
			style={active ? activeStyles : {}}
			_hover={activeStyles}
			onClick={() => {
				setClickedSummaryPage();
				toggleSummaryPageSettings();
			}}
		>
			<Flex alignItems="center">
				<Tooltip label="This summary page lets the user review their answer before they submit the form." placement="top">
					<span>
						<Icon mr={2} color="neutral-fg" path={mdiInformationOutline} />
					</span>
				</Tooltip>
				<IconButton
					aria-label="Delete summary page"
					size="sm"
					variant="ghost"
					icon={<Icon path={mdiTrashCanOutline} />}
					onClick={(e) => {
						e.stopPropagation();
						toggleSummaryPage();
						useLeftSidebarStore.getState().unsetClickedRow();
					}}
				/>
				<Text ml={2} color="neutral-fg" fontSize="sm">
					Summary page
				</Text>
			</Flex>
			<Divider my={6} />
			<Box
				mx="auto"
				borderRadius={borderRadius}
				backgroundImage={background_image ? `url('${background_image}')` : ''}
				backgroundRepeat={background_repeat === 'full' ? 'no-repeat' : background_repeat}
				backgroundSize={background_repeat === 'full' ? '100%' : (retinaImages ? bgSize * 2 : bgSize) + 'px'}
				backgroundPosition={background_position}
				backgroundColor={bg_color}
				maxW={width || structureWidth}
				style={{
					...getPadding({ padding, multiPadding }, true),
					border,
					borderCollapse: 'collapse',
				}}
				data-testid="summary-page"
				id="summary-page"
			>
				<Heading fontSize="lg" mb={5} fontFamily={fontFamily}>
					{summaryPage.title}
				</Heading>
				<Stack spacing={5}>
					{allFields
						?.reduce((acc, item) => {
							const { page } = item;
							if (!acc[page]) acc[page] = [];
							acc[page].push(item);
							return acc;
						}, [])
						.map((pageFields, index) => (
							<Box key={index}>
								<Flex borderTopWidth={1} py={2.5} justify="end">
									<Box color="neutral-fg" fontSize="sm" fontFamily={fontFamily}>
										Page {index + 1}
										<IconButton ml={0.5} size="xs" variant="ghost" pointerEvents="none" icon={<Icon path={mdiChevronUp} />} />
										<IconButton
											data-testid="edit-page-button"
											ml={3}
											size="xs"
											variant="ghost"
											pointerEvents="none"
											icon={<Icon path={mdiPencilOutline} />}
										/>
									</Box>
								</Flex>
								<Stack spacing={4}>
									{pageFields.map(({ label, type, required }) => (
										<SummaryField key={label} label={label} type={type} required={required} fieldStyles={summaryPage.fieldStyles} />
									))}
								</Stack>
							</Box>
						))}
				</Stack>
				<Button {...summaryPage.buttonStyles} />
			</Box>
		</Box>
	);
};

const SummaryField = ({ label, required, type, fieldStyles }) => {
	const resetStyles = { lineHeight: 'initial', width: '100%', minWidth: 0, outline: 'none', flex: 1 };

	const {
		labelFont,
		labelFontColour,
		labelFontSize,
		labelFontWeight,
		labelFontStyle,
		labelTextDecoration,
		labelPosition,
		labelWidth,
		asteriskColor,

		fieldFontColour,
		fieldFontSize,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFont,
	} = fieldStyles;

	const labelDimension = labelPosition === 'row' ? { width: `${labelWidth}px` } : { minHeight: `${labelWidth}px` };

	const labelStyle = {
		color: labelFontColour,
		fontSize: labelFontSize,
		fontWeight: labelFontWeight,
		fontStyle: labelFontStyle,
		textDecoration: labelTextDecoration,
		...labelDimension,
		...getFormFieldFontStyles(labelFont),
	};

	const asteriskStyles = {
		fontFamily: 'inherit',
		fontWeight: 'normal',
		fontStyle: 'normal',
		textDecoration: 'none',
		color: asteriskColor,
	};

	const fieldStyle = {
		...resetStyles,
		color: fieldFontColour,
		fontSize: fieldFontSize,
		fontWeight: fieldFontWeight,
		fontStyle: fieldFontStyle,
		textDecoration: fieldTextDecoration,
		...getFormFieldFontStyles(fieldFont),
	};

	return (
		<Flex gap={2} direction={labelPosition} align={labelPosition === 'row' ? 'center' : ''}>
			<Text color="neutral-fg" fontWeight="medium" style={labelStyle}>
				{label}
				{required && <span style={asteriskStyles}> *</span>}
			</Text>
			{type === 'file_upload' ? (
				<Flex bg="chakra-subtle-bg" py={2} px={3} borderRadius="sm" align="center" style={fieldStyle}>
					<Icon path={mdiFileDocumentOutline} mr={1} color="neutral-fg" />
					uploaded_file.pdf
					<Text style={{ fontSize: '.875rem' }} ml={2} color="chakra-subtle-text" fontFamily={fieldFont}>
						(728.9 kB)
					</Text>
				</Flex>
			) : (
				<Text style={fieldStyle}>{exampleData[type]}</Text>
			)}
		</Flex>
	);
};

export default SummaryPage;
