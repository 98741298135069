/*
 * KEEP THIS FILE ISOMORPHIC
 * DO NOT IMPORT ANYTHING THAT IS NOT ISOMORPHIC AND WONT WORK ON THE NODE APP
 */
import React, { PureComponent } from 'react';

import LandingRow from './landingPage/Row.react';
import SummaryPage from './landingPage/SummaryPage.react';

class TransformerComponent extends PureComponent {
	render() {
		const content = this.props.content;

		const groupedRows = {};

		const sortedRows = [...content.rows].sort((a, b) => a.pageIndex - b.pageIndex);

		content.rows.forEach((row, i) => {
			let generalStyles = {
				text_line_height: content.text_line_height || '1',
				font_size: content.font_size,
				font_family: content.font_family,
				color: content.color === 'transparent' ? '#000000' : content.color,
				background_image: content.background_image,
				background_repeat: content.background_repeat,
				bg_color: content.bg_color,
				title: content.title,
				borderWidth: content.borderWidth,
				borderColor: content.borderColor,
				structureWidth: content.structureWidth,
				retina_images: content.retina_images,
			};

			const lastRow = sortedRows.length - 1 === i;

			const rowComp = (
				<LandingRow
					{...row}
					key={row.id}
					lastPage={content.lastPage}
					general_styles={generalStyles}
					lastRow={lastRow}
					entityId={this.props.entityId}
					successAction={content.successAction}
				/>
			);

			if (typeof row.pageIndex !== 'undefined' && row.type !== 'pagePlaceholder') {
				if (groupedRows[row.pageIndex]) {
					groupedRows[row.pageIndex].push(rowComp);
				} else {
					groupedRows[row.pageIndex] = [rowComp];
				}
			}
		});

		let pages = [];

		for (const key in groupedRows) {
			const page = groupedRows[key].map((item) => item);

			pages = [
				...pages,
				<div
					key={`page-${key}`}
					data-page-index={key}
					className="form-page"
					style={{
						display: parseInt(key, 10) > 0 ? 'none' : 'flex',
						opacity: parseInt(key, 10) > 0 ? 0 : 1,
						flexDirection: 'column',
					}}
				>
					{page}
				</div>,
			];
		}

		return (
			<div ref="content_html" className="transformer-content">
				{pages}
				<SummaryPage content={this.props.content} />
			</div>
		);
	}
}

export default TransformerComponent;
