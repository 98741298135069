import React from 'react';

import { AllComponentTypes } from 'shared/src/utils/shared.js';

import { Checkbox } from './Checkbox.react';
import Element from './Element.react';

export const GDPRbox = (props) => {
	return <Checkbox {...props} labelHide={false} type={AllComponentTypes.checkbox} value={props.value} descriptionHide />;
};

export default Element(GDPRbox);
