import React from 'react';

import { AllComponentTypes } from 'shared/src/utils/shared.js';

import Checkbox, { generateCheckboxClass } from './Checkbox.react';

export const GDPRbox = (props) => {
	return (
		<Checkbox {...props} labelHide={false} type={AllComponentTypes.checkbox} value={props.value} label={props.label} descriptionHide />
	);
};

export const generateGDPRboxClass = (component) => {
	return `
		${generateCheckboxClass(component)}
	`;
};

export default GDPRbox;
