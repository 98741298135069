import React, { useMemo } from 'react';

import { FieldComponentTypes } from 'shared/src/utils/shared.js';

import { ComparerTypesLabels, ComponentTypeToComparer } from '@/util/resources';

import { MultiSelectWithTags } from '@/components/gui/shared/MultiSelectWithTags';

export interface CriteriaComparerProps {
	fieldType: keyof typeof FieldComponentTypes;
	value: string;
	setValue: (newValue: string | string[]) => void;
	placeholder?: string;
	disabled?: boolean;
	hasError?: boolean;
}

export const CriteriaComparer: React.FC<CriteriaComparerProps> = ({ fieldType, value, setValue, placeholder, disabled, hasError }) => {
	const options = useMemo(() => {
		if (!ComponentTypeToComparer[fieldType]) return [];

		return ComponentTypeToComparer[fieldType].map((comparer) => ({
			title: ComparerTypesLabels[comparer],
			value: comparer,
		}));
	}, [fieldType]);

	if (!options.length) return null;

	return (
		<MultiSelectWithTags
			options={options}
			placeholder={placeholder}
			value={value}
			onChange={(data) => setValue(data)}
			disabled={disabled}
			hasError={hasError}
		/>
	);
};
