import { memo, useCallback, useMemo } from 'react';

import { Box, Button, Card, Text } from '@chakra-ui/react';
import { FieldComponentTypes } from 'shared/src/utils/shared.js';
import { useShallow } from 'zustand/react/shallow';

import useContentStore from '@/stores/ContentStore';

import { ConditionsActionTypes, LogicErrors } from '@/util/resources';

import { IConditionField, LogicAction } from '@/components/Conditions/models/model';
import { createBaseLogicAction } from '@/components/Conditions/utils';
import { IMultiSelectWithTagsDefaultOption } from '@/components/gui/shared/MultiSelectWithTags';

import { Action } from './Action';

export interface IActionsProps {
	logicIdx: number;
}

const LogicActions: React.FC<IActionsProps> = ({ logicIdx }) => {
	const { allFields, changeLogicActions, changeLogicAction, actions, logicErrors, canBeModified } = useContentStore(
		useShallow((state) => ({
			allFields: state.allFields as IConditionField[],
			changeLogicActions: state.changeLogicActions,
			changeLogicAction: state.changeLogicAction,
			actions: (state.content.logics?.[logicIdx]?.actions || []) as LogicAction[],
			logicErrors: (state.logicsErrors?.[logicIdx] || []) as { errors: string[]; actions: { errors: string[] }[] },
			canBeModified: state.canBeModified,
		})),
	);
	const hasActions = Boolean(actions?.length);

	const options: IMultiSelectWithTagsDefaultOption[] = useMemo(() => {
		if (!allFields || !allFields.length) {
			return [];
		}

		return allFields.map((field) => ({
			title: field.label,
			subtitle: field.name,
			value: field.id,
			group: `Page ${field.page + 1}`,
		}));
	}, [allFields]);

	const onChangeAction = useCallback(
		(action: LogicAction, actionIdx: number, actionValue: keyof typeof ConditionsActionTypes) => {
			changeLogicAction(logicIdx, actionIdx, { ...action, action: actionValue });
		},
		[logicIdx],
	);

	const onChangeField = useCallback(
		(action: LogicAction, actionIdx: number, fieldId: keyof typeof FieldComponentTypes) => {
			const allFields = useContentStore.getState().allFields;
			const field = allFields.find((field) => field.id === fieldId);
			changeLogicAction(logicIdx, actionIdx, { ...action, field });
		},
		[logicIdx],
	);

	const onDeleteAction = useCallback(
		(action: LogicAction) => {
			const actions = useContentStore.getState().content.logics[logicIdx].actions || [];
			const newActions = actions.filter((a) => a.id !== action.id);
			changeLogicActions(logicIdx, newActions);
		},
		[logicIdx],
	);

	const onAddAction = useCallback(() => {
		const actions = useContentStore.getState().content.logics[logicIdx].actions || [];
		const newActions = [...actions, createBaseLogicAction()];
		changeLogicActions(logicIdx, newActions);
	}, [logicIdx]);

	return (
		<div>
			<Text my={5}>Then execute the following actions:</Text>

			<Card
				shadow="none"
				variant="filled"
				size="lg"
				p={4}
				flexWrap="wrap"
				bg={logicErrors?.errors?.includes(LogicErrors.missingActions) ? 'danger-bg' : undefined}
			>
				{hasActions && (
					<div data-testid="actions-wrapper">
						{actions.map((action, idx) => (
							<Action
								key={action.id}
								action={action}
								index={idx}
								fieldOptions={options}
								onChangeAction={onChangeAction}
								onChangeField={onChangeField}
								onDeleteAction={onDeleteAction}
								isLast={actions.length - 1 === idx}
								disabled={!canBeModified}
								errors={logicErrors?.actions?.[idx]?.errors || []}
							/>
						))}
					</div>
				)}

				<Box display="flex" w="100%" justifyContent={hasActions ? 'end' : 'center'}>
					<Button
						variant="ghost"
						color="primary-fg"
						size="sm"
						p={!hasActions && 4}
						mt={hasActions && 2}
						onClick={onAddAction}
						data-testid="action-create-btn"
						isDisabled={!canBeModified}
					>
						Add action
					</Button>
				</Box>
			</Card>
		</div>
	);
};

export const Actions = memo(LogicActions);
