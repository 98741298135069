import { FC, memo, ReactElement, useCallback } from 'react';

import { Box, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { isUndefined } from 'lodash';
import isEqual from 'lodash/isEqual';
import RenderIfVisible from 'react-render-if-visible';
import { generateGuid } from 'shared/src/utils/shared.js';
import { useDeepCompareMemo } from 'use-deep-compare';

import { Icon } from '@/components/gui/shared/Icon';

type ColType = {
	item: ReactElement;
	restProps?: Record<string, unknown>;
};

type ColHeaderType = {
	label: string | ReactElement;
	width: string | string[];
	isSortable?: boolean;
	isCentered?: boolean;
	item?: ReactElement;
	restHeaderProps?: Record<string, unknown>;
};

interface Props {
	headers: Record<string, ColHeaderType>;
	data: Record<string, ColType>[];
	order: string;
	orderByTypes: string[]; // Order is important. ['OrderAscending', 'OrderDescending', ...]
	orderCategories: Record<string, { filter: string; label: string }>;
	orderDirection: () => boolean;
	changeOrder: (order: string) => void;
	defaultOrderBy?: string;
	loaders: { table: boolean; tableDataUpdate: boolean };
	skeletonComponent?: ReactElement;
	'data-testid'?: string;
	isVirtualized?: boolean;
	parentRef?: React.RefObject<HTMLDivElement>;
}

const BaseTable: FC<Props> = ({
	headers,
	loaders,
	data,
	order,
	orderCategories,
	orderDirection,
	orderByTypes,
	changeOrder,
	skeletonComponent,
	defaultOrderBy,
	isVirtualized = false,
	parentRef,
	...rest
}) => {
	const tableHeaders = useDeepCompareMemo(() => {
		const baseOrderDirection = () => {
			const customOrder = orderDirection?.();

			if (!isUndefined(customOrder)) {
				return customOrder;
			}

			const index = orderByTypes.findIndex((item) => item === order);
			if (index !== -1) {
				return index % 2 === 0;
			}
		};

		const getArrow = (type) => {
			if (order.includes(type)) {
				return (
					<>
						<Tooltip openDelay={500} label={baseOrderDirection() ? 'Sorted ascending' : 'Sorted descending'}>
							<IconButton
								size="xs"
								className="notranslate"
								colorScheme="primary"
								icon={<Icon path={baseOrderDirection() ? mdiArrowUp : mdiArrowDown} boxSize="4" />}
								variant={'unstyled'}
								ml={1}
								aria-label={baseOrderDirection() ? 'Sorted ascending' : 'Sorted descending'}
							/>
						</Tooltip>
					</>
				);
			}
		};

		const baseChangeOrder = (type) => {
			if (order.includes(type)) {
				if (!baseOrderDirection()) {
					return changeOrder && changeOrder(type + 'Ascending');
				}
				return changeOrder && changeOrder(defaultOrderBy);
			}
			return changeOrder && changeOrder(type + 'Descending');
		};

		return Object.keys(headers).map((header, index) => {
			const currentHeader = headers[header];

			return (
				<Th
					key={`table-header-${index}`}
					w={currentHeader.width}
					onClick={currentHeader.isSortable && (() => baseChangeOrder(orderCategories[header].filter))}
					style={{ userSelect: 'none' }}
					{...currentHeader.restHeaderProps}
				>
					{currentHeader.item ? (
						currentHeader.item
					) : (
						<Box
							display="flex"
							alignItems="center"
							justifyContent={currentHeader.isCentered ? 'center' : currentHeader.restHeaderProps?.isNumeric ? 'end' : 'unset'}
						>
							<Text>{currentHeader.label}</Text>
							{currentHeader.isSortable && getArrow(orderCategories[header].filter)}
						</Box>
					)}
				</Th>
			);
		});
	}, [changeOrder, headers, order, orderByTypes, orderCategories, orderDirection]);

	const renderCellData = useCallback(
		(row) => {
			return Object.keys(headers).map((column, index) => {
				const cellData = row[column];

				return (
					<Td key={`table-cell-${index}`} w={headers[column].width} {...cellData.restProps}>
						{cellData.item}
					</Td>
				);
			});
		},
		[headers],
	);

	const tableData = data.map((row, index) => {
		return isVirtualized ? (
			<RenderIfVisible
				initialVisible
				defaultHeight={75}
				rootElement={'tr'}
				root={parentRef.current}
				placeholderElement={'td'}
				key={generateGuid(`table-row-${index}`)}
			>
				{renderCellData(row)}
			</RenderIfVisible>
		) : (
			<Tr key={`table-row-${index}`}>{renderCellData(row)}</Tr>
		);
	});

	return (
		<div>
			<Table {...(rest['data-testid'] ? { 'data-testid': rest['data-testid'] } : {})}>
				<Thead>
					<Tr>{tableHeaders}</Tr>
				</Thead>
				<Tbody opacity={loaders.tableDataUpdate ? '0.4' : '1.0'} transition={'opacity 0.2s ease-in'}>
					{loaders.table ? skeletonComponent : tableData}
				</Tbody>
			</Table>
		</div>
	);
};

export default memo(BaseTable, isEqual);
