import { useEffect, useMemo, useState } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { MultiValue, Select } from 'chakra-react-select';
import config from 'config/config';
import Pusher from 'pusher-js';
import { generateGuid } from 'shared/src/utils/shared.js';

import useEntitiesStore from '@/stores/EntitiesStore';
import useNotificationStore from '@/stores/NotificationStore';
import useUserStore from '@/stores/UserStore';

import { NotificationTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';

export interface TableCopyModalProps {
	isOpen: boolean;
	onClose: () => void;
	entityIds: string[];
}

interface TenantOption {
	label: string;
	value: string;
}

const TableCopyModal: React.FC<TableCopyModalProps> = ({ isOpen, onClose, entityIds }) => {
	const toast = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTenants, setSelectedTenants] = useState<MultiValue<TenantOption>>([]);
	const { currentTenant, tenants } = useUserStore((state) => ({
		currentTenant: state.currentTenant,
		tenants: state.tenants,
	}));
	const copyToEnvs = useEntitiesStore((state) => state.copyToEnvs);
	const showNotification = useNotificationStore((state) => state.showNotification);

	const tenantOptions: TenantOption[] = useMemo(
		() =>
			tenants
				.filter((tenant) => tenant.id !== currentTenant.id)
				.map((tenant) => ({
					label: tenant.displayName,
					value: tenant.id,
				})),
		[currentTenant, tenants],
	);

	const pusher = useMemo(
		() =>
			new Pusher(config.pusher_key, {
				cluster: 'eu',
			}),
		[],
	);
	const channel = useMemo(() => pusher.subscribe('copy-to-envs'), [pusher]);

	const onCopyClick = () => {
		setIsLoading(true);

		copyToEnvs(
			entityIds,
			selectedTenants.map(({ value }) => value),
			(eventName) => {
				const id = generateGuid();

				showNotification({ id, type: NotificationTypes.LOADING, text: 'Copying forms has started' });

				channel.bind(eventName, (data) => {
					if (toast.isActive(id)) {
						toast.close(id);
					}

					setTimeout(() => {
						if (data.FailedForms.length) {
							showNotification({
								type: NotificationTypes.WARNING,
								text: 'Some forms failed to copy:',
								description: <CopyErrors errors={data.FailedForms} />,
							});
						} else {
							showNotification({ type: NotificationTypes.SUCCESS, text: 'Copying forms completed successfully' });
						}
					}, 500);
				});

				onClose();
			},
		).finally(() => setIsLoading(false));
	};

	useEffect(() => {
		setSelectedTenants([]);
	}, [isOpen]);

	return (
		<Modal id="copy-forms" isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					{entityIds.length === 1
						? 'Select which environment to copy this form to'
						: `Select which environment to copy (${entityIds.length}) forms to`}
				</ModalHeader>

				<ModalCloseButton />

				<ModalBody>
					<Text mb={3} color="chakra-subtle-text">
						Current environment: {currentTenant.displayName}
					</Text>

					<FormControl id="tenants-dropdown">
						<FormLabel fontSize="lg" fontWeight="semibold">
							Available environments
						</FormLabel>

						<Select
							useBasicStyles
							isRequired
							isMulti
							isSearchable
							isDisabled={isLoading}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							selectedOptionStyle="check"
							options={tenantOptions}
							value={selectedTenants}
							onChange={(selectedOptions) => {
								setSelectedTenants(selectedOptions);
							}}
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" colorScheme="blackAlpha" onClick={onClose}>
							Cancel
						</Button>

						<Button isDisabled={!selectedTenants.length} isLoading={isLoading} onClick={onCopyClick}>
							Copy
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

const CopyErrors = ({ errors }) => {
	const { isOpen, onToggle } = useDisclosure();
	const tenants = useUserStore((state) => state.tenants);
	const noOfLines = errors.map((error) => error.Forms.length + 1).reduce((sum, val) => sum + val, 0);

	return (
		<Box mt={3}>
			<Collapse in={isOpen} startingHeight={errors[0].Forms.length === 1 ? '48px' : '72px'}>
				<Flex direction="column" gap={3} maxHeight="400px" overflowY={isOpen ? 'auto' : 'visible'}>
					{errors.map((error) => (
						<List>
							<ListItem noOfLines={1} fontWeight={600}>
								{tenants.find((tenant) => tenant.id === error.TenantId).displayName}
							</ListItem>
							{error.Forms.map((form) => (
								<ListItem noOfLines={1}>{form.FormName}</ListItem>
							))}
						</List>
					))}
				</Flex>
			</Collapse>

			{noOfLines > 3 && (
				<Button variant="link" onClick={onToggle}>
					{isOpen ? 'Show less' : 'Show more'}
					<Icon path={isOpen ? mdiChevronUp : mdiChevronDown} />
				</Button>
			)}
		</Box>
	);
};

export default TableCopyModal;
