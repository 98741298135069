import React from 'react';

import { AllComponentTypes, ComponentDefaults, getDesignerAlignment, getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';

class Button extends React.PureComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.submit_button] };

	render() {
		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';
		const { back_button, successAction } = this.props;

		const backButton = this.props.pageIndex > 0 && (
			<button
				className={`lp-flex-container lp-flex-align-center lp-flex-justify-center back-button`}
				aria-description={`Takes you back to page ${this.props.pageIndex} of ${this.props.lastPage + 1}`}
			>
				{back_button.text}
			</button>
		);
		const nextButton = (
			<button
				className={`lp-flex-container lp-flex-align-center lp-flex-justify-center next-button`}
				aria-description={`Takes you to page ${this.props.pageIndex + 2} of ${this.props.lastPage + 1}`}
			>
				{this.props.text}
			</button>
		);
		const submitButton = (
			<button
				className={`lp-flex-container lp-flex-align-center lp-flex-justify-center submit-button`}
				aria-description={`${this.props.lastPage ? 'You are on the final page. ' : ''}Submits the form${successAction.type === 'redirectUrl' ? ' and redirects to another page' : ''}`}
			>
				{this.props.text}
			</button>
		);

		const buttonComp =
			this.props.pageIndex === this.props.lastPage ? (
				<>
					{backButton}
					{submitButton}
				</>
			) : (
				<>
					{backButton}
					{nextButton}
				</>
			);

		return (
			<>
				<div
					className={`component global-button-wrapper button-component lp-flex-container lp-flex-align-center button${id}`}
					id={id}
					data-component-type={this.props.type}
				>
					{buttonComp}
				</div>
				{this.props.pageIndex === this.props.lastPage && (
					<div className="lp-flex-container lp-flex-align-center">
						<div className="message-popup success-popup" role="alert" aria-live="assertive"></div>
						<div className="message-popup fail-popup" role="alert" aria-live="assertive"></div>
					</div>
				)}
			</>
		);
	}
}

export function generateButtonClass(component, slotWidth, contentModel, pageIndex) {
	const lastPage = contentModel.lastPage;

	const { back_button } = component;

	const backButtonData = { ...component, ...back_button };

	const dimensions =
		component.width === 'custom'
			? `padding: 0;
            width: ${component.customSize.width}px;
            height: ${component.customSize.height}px`
			: `
                ${getPadding(component)}
                width: ${component.width};
            `;

	const backDimensions =
		backButtonData.width === 'custom'
			? `padding: 0;
            width: ${backButtonData.customSize.width}px;
            height: ${backButtonData.customSize.height}px`
			: `
                ${getPadding(backButtonData)}
                width: ${backButtonData.width};
            `;

	const alignment =
		pageIndex === lastPage && lastPage === 0 ? getDesignerAlignment(component.alignment) : pageIndex > 0 ? 'space-between' : 'flex-end';

	return `

    .button${component.id.replace(/-/g, '')} {
        background-color: ${component.background_color || 'transparent'};
        justify-content: ${alignment};
    }

    ${
			component.width === '100%'
				? `.button${component.id.replace(/-/g, '')} a {
                    width: 100%;
                }`
				: ''
		}

    .button${component.id.replace(/-/g, '')} .submit-button, .button${component.id.replace(/-/g, '')} .next-button {
        background-color: ${component.color || 'transparent'};
        border-width: ${component.borderWidth}px;
        border-color: ${component.borderColor};
        border-radius: ${component.borderRadius}px;
        text-decoration :${component.textDecoration};
        color: ${component.text_color};
		font-weight: ${component.fontWeight};
        font-size: ${component.fontSize / 16}rem;
        font-family: ${component.fontFamily};
        font-style: ${component.fontStyle};
        margin: ${component.width !== '100%' ? `${component.margin}px` : `0`};
        box-shadow: ${getShadowValFromProps(component.box_shadow)};
        ${dimensions}
    }

	.button${component.id.replace(/-/g, '')} .back-button {
        background-color: ${backButtonData.color || 'transparent'};
        border-width: ${backButtonData.borderWidth}px;
        border-color: ${backButtonData.borderColor};
        border-radius: ${backButtonData.borderRadius}px;
        text-decoration :${backButtonData.textDecoration};
        color: ${backButtonData.text_color};
		font-weight: ${backButtonData.fontWeight};
        font-size: ${backButtonData.fontSize / 16}rem;
        font-family: ${backButtonData.fontFamily};
        font-style: ${backButtonData.fontStyle};
        margin: ${backButtonData.width !== '100%' ? `${backButtonData.margin}px` : `0`};
        box-shadow: ${getShadowValFromProps(backButtonData.box_shadow)};
        ${backDimensions}
    }

		.button${component.id.replace(/-/g, '')} .submit-button:focus,
		.button${component.id.replace(/-/g, '')} .next-button:focus {
			outline: 2px solid #007fff;
			outline-offset: 2px;
		}

		.button${component.id.replace(/-/g, '')} .back-button:focus {
			outline: 2px solid #007fff;
			outline-offset: 10px;
		}
    `;
}

export default Button;
