import React from 'react';

import { getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';

import Element from './Element.react';

export const Recaptcha = (props) => {
	return (
		<div
			style={{
				backgroundColor: props.background_color,
				display: 'flex',
				alignItems: 'center',
				justifyContent: props.alignment,
				...getPadding(props, true),
			}}
		>
			<div
				style={{
					border: '1px solid #d3d3d3',
					width: '100%',
					maxWidth: 300,
					minWidth: 180,
					height: 75,
					boxShadow: getShadowValFromProps(props.box_shadow),
					borderRadius: 3,
					background: '#f9f9f9',
					color: '#bebebe',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				reCAPTCHA Placeholder
			</div>
		</div>
	);
};

export default Element(Recaptcha);
