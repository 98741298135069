import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { components, Select } from 'chakra-react-select';

import { Tooltip } from '@/components/gui/content/helper/Tooltip.react';

const fieldTypeOptions = [
	{ value: 'name', label: 'Full name', isGroupTypeSupported: true },
	{ value: 'honorific-prefix', label: 'Honorific prefix', isGroupTypeSupported: true, additionalInfo: 'e.g. Mrs., Mr., Miss, Ms., Dr.' },
	{ value: 'given-name', label: 'First name', isGroupTypeSupported: true },
	{ value: 'family-name', label: 'Last name', isGroupTypeSupported: true },
	{ value: 'honorific-suffix', label: 'Honorific suffix', isGroupTypeSupported: true, additionalInfo: 'e.g. Jr., B.Sc., PhD., IV' },
	{ value: 'username', label: 'Username' },
	{ value: 'current-password', label: 'Current password' },
	{ value: 'organization-title', label: 'Organization title' },
	{ value: 'organization', label: 'Organization' },
	{ value: 'street-address', label: 'Street address', isGroupTypeSupported: true },
	{ value: 'country', label: 'Country code', isGroupTypeSupported: true },
	{ value: 'country-name', label: 'Country name', isGroupTypeSupported: true },
	{ value: 'postal-code', label: 'Postal code', isGroupTypeSupported: true },
	{ value: 'cc-name', label: 'Credit card name' },
	{ value: 'language', label: 'Language' },
	{ value: 'bday', label: 'Birth date' },
	{ value: 'bday-day', label: 'Birth day' },
	{ value: 'bday-month', label: 'Birth month' },
	{ value: 'bday-year', label: 'Birth year' },
	{ value: 'sex', label: 'Sex' },
];

const groupTypeOptions = [
	{ value: 'shipping', label: 'Shipping' },
	{ value: 'billing', label: 'Billing' },
];

const Option = (props) => (
	<components.Option {...props}>
		{props.label}
		{props.data.additionalInfo && (
			<Text variant="tiny" mt={0.5} {...(props.isSelected && { color: 'inherit' })}>
				{props.data.additionalInfo}
			</Text>
		)}
	</components.Option>
);

const AutocompleteSetting = ({ type, value, onChange }) => {
	const fieldType = (Boolean(value) && fieldTypeOptions.find((option) => option.value === value.fieldType)) || null;
	const groupType = (Boolean(value) && groupTypeOptions.find((option) => option.value === value.groupType)) || null;
	const isGroupTypeDisabled = !fieldType?.isGroupTypeSupported;

	const handleSelectChange = (name, option) => {
		if (onChange) {
			const newValue = { ...value, [name]: option?.value || null };

			if (name === 'fieldType' && !option?.isGroupTypeSupported && newValue.groupType) {
				newValue.groupType = null;
			}

			onChange({ type, value: newValue });
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			<FormControl mb={5}>
				<FormLabel as="label">
					<Box display="inline-flex" pos="relative">
						Field type
						<Tooltip>Type of data expected for this field, for example First name, Address, etc.</Tooltip>
					</Box>
				</FormLabel>
				<Select
					useBasicStyles
					menuPlacement="top"
					menuPosition="fixed"
					isClearable
					placeholder="Select a field type"
					options={fieldTypeOptions}
					value={fieldType}
					components={{
						Option,
					}}
					onChange={(option) => {
						handleSelectChange('fieldType', option);
					}}
				/>
			</FormControl>

			<FormControl mb={5}>
				<FormLabel as="label">
					<Box display="inline-flex" pos="relative">
						Field subtype
						<Tooltip>Additional type of data expected for this field, for example Billing, Shipping</Tooltip>
					</Box>
				</FormLabel>
				<Select
					useBasicStyles
					menuPosition="fixed"
					isClearable
					isDisabled={isGroupTypeDisabled}
					placeholder="Select a field subtype"
					options={groupTypeOptions}
					value={groupType}
					components={{
						Option,
					}}
					onChange={(option) => {
						handleSelectChange('groupType', option);
					}}
				/>
			</FormControl>
		</Box>
	);
};

export default AutocompleteSetting;
