import React, { createRef } from 'react';

import { Box, Button, FormControl, FormLabel, Input, Menu, MenuItem, MenuList, ModalBody, ModalFooter, VStack } from '@chakra-ui/react';
import config from 'config/config';
import { ErrorMessages, SuccessMessages } from 'config/messages';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';
import useTemplateStore from '@/stores/TemplateStore';
import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { ApiAuthorisationModes, ModalTypes, NotificationTypes, PublicOptions, TemplateAccessLevel } from '@/util/resources';

import EnvironmentSelection from '@/components/gui/shared/EnvironmentSelection';
import Modal from '@/components/gui/shared/modals/Modal.react';

export class TemplateModal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();

		this.state = this.getInitialState();
	}

	componentDidMount() {
		let templates = this.props.templates.length ? this.props.templates : [];
		if (templates) {
			templates.forEach((template) => {
				if (
					this.props.contentId &&
					template.id &&
					(template.id.includes(this.props.contentId) || template.id.includes(this.props.contenttestid)) &&
					template.AuthorisationMode === ApiAuthorisationModes.Private
				) {
					this.setState({ name: template.title, selectedTemplate: template, selectedTemplateId: template.id });
				}
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contentId && !this.props.contentId) {
			this.unsetTemplate();
		}
		if (this.props.template !== prevProps.template) {
			this.setState({
				name: this.props.template?.title || '',
				tenants: this.props.template?.tenants || [],
				selectTenants: this.props.template?.AuthorisationMode === ApiAuthorisationModes.Organization && this.props.template?.tenants.length,
				AuthorisationMode: this.props.template?.AuthorisationMode || TemplateAccessLevel[0].value,
				selectedTemplate: this.props.template || undefined,
				selectedTemplateId: this.props.template?.id || undefined,
				selectedImageSrc: this.props.template?.imageSrc || undefined,
				selectedImageMobileSrc: this.props.template?.imageMobileSrc || undefined,
				selectedCategories: this.props.template?.categories || undefined,
			});
		}
	}

	render() {
		const dropDown = this.state.name && !this.state.selectedTemplate && this.renderDropDown();

		const invalidName = !this.state.name || !this.state.name.replace(/\s/g, '').length;
		const invalidTenants =
			this.state.AuthorisationMode === ApiAuthorisationModes.Organization && this.state.selectTenants && !this.state.tenants.length;
		return (
			<Modal
				display={this.props.show}
				title={this.props.template ? 'Share template' : 'Save as template'}
				modalType={ModalTypes.TEMPLATE_MODAL}
				size="lg"
			>
				<ModalBody>
					<VStack spacing={4}>
						<FormControl isRequired>
							<FormLabel>Template name</FormLabel>
							<Input
								ref={this.nameInputRef}
								data-testid="save-template-name"
								value={this.state.name}
								readOnly={this.state.selectedTemplate}
								onChange={(e) => this.setState({ name: e.target.value })}
							/>
							<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
								{dropDown}
							</Box>
						</FormControl>

						{import.meta.env.MODE === 'test' || this.props.allowProdSave ? (
							<React.Fragment>
								<FormControl>
									<FormLabel>Image</FormLabel>
									<Input value={this.state.imageSrc} onChange={(e) => this.setState({ imageSrc: e.target.value })} />
								</FormControl>
								<FormControl>
									<FormLabel>Mobile image</FormLabel>
									<Input value={this.state.imageMobileSrc} onChange={(e) => this.setState({ imageMobileSrc: e.target.value })} />
								</FormControl>
								<FormControl>
									<FormLabel>Categories</FormLabel>
									<Input value={this.state.categories} onChange={(e) => this.setState({ categories: e.target.value })} />
								</FormControl>
							</React.Fragment>
						) : (
							<>
								<EnvironmentSelection
									label="Save template in"
									authMode={this.state.AuthorisationMode}
									tenants={this.state.tenants}
									availableTenants={this.props.availableTenants}
									selectTenants={this.state.selectTenants}
									onChangeAuthMode={(value) => this.setState({ AuthorisationMode: value })}
									onChangeTenants={(value) => this.setState({ tenants: value })}
									onChangeSelectTenants={(value) => this.setState({ selectTenants: value })}
								/>
							</>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter>
					{this.state.selectedTemplate ? (
						<Box display="flex" justifyContent="flex-end">
							{!this.props.template && (
								<Button variant="ghost" onClick={this.unsetTemplate} isDisabled={this.state.loading}>
									Cancel
								</Button>
							)}
							<Button onClick={this.overrideTemplate} isDisabled={this.state.loading}>
								{this.props.template ? 'Share' : 'Overwrite existing'}
							</Button>
						</Box>
					) : (
						<Box display="flex" justifyContent="flex-end">
							<Button
								data-testid="save-template-button"
								onClick={this.saveTemplate}
								isDisabled={this.state.loading || invalidName || invalidTenants}
							>
								Save
							</Button>
						</Box>
					)}
				</ModalFooter>
			</Modal>
		);
	}

	getInitialState = () => ({
		loading: false,
		name: this.props.name || '',
		imageSrc: '',
		imageMobileSrc: '',
		categories: '',
		tenants: [],
		selectTenants: false,
		AuthorisationMode: TemplateAccessLevel[0].value,
		selectedTemplate: this.props.selectedTemplate || undefined,
		selectedTemplateId: undefined,
		selectedImageSrc: undefined,
		selectedImageMobileSrc: undefined,
		selectedCategories: undefined,
	});

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	saveTemplate = () => {
		const contentStore = useContentStore.getState();

		let templates = this.props.templates.length ? this.props.templates : [];

		const existingName =
			templates &&
			templates.length &&
			templates.find((item) => {
				return item.title.toLowerCase() === this.state.name.toLowerCase();
			});

		if (existingName) {
			return showNotification({ type: NotificationTypes.ERROR, text: 'A template with that name already exists' });
		}

		/* istanbul ignore next */
		let successCb = (templateId) => {
			this.setState(this.getInitialState());
			contentStore.setTemplateId(templateId);
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_SAVED });
			useModalStore.getState().closeModal(ModalTypes.TEMPLATE_MODAL);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_SAVED });
		};

		/* istanbul ignore next */
		let limitErrorCb = () => {
			this.setState({ loading: false });
			showNotification({
				type: NotificationTypes.ERROR,
				text: ErrorMessages.TEMPLATE_NOT_SAVED_LIMIT_EXCEDED.replace(
					'{template_limit}',
					config.template_market.market_private_templates_limit.toString(),
				),
			});
		};

		let limit = templates.filter((template) => template.AuthorisationMode === ApiAuthorisationModes.Private).length;
		let categories =
			(this.state.categories &&
				this.state.categories
					.trim()
					.split(',')
					.map((cat) => cat.trim())) ||
			[];

		this.setState({ loading: true });

		const userData = getUser();

		this.props.saveNewTemplate(
			{
				title: this.state.name,
				categories: categories,
				imageSrc: this.state.imageSrc,
				imageMobileSrc: this.state.imageMobileSrc,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				tenants: this.state.tenants,
				json: contentStore.content,
			},
			limit,
			this.state.AuthorisationMode,
			successCb,
			limitErrorCb,
			errorCb,
		);
	};

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let templates = this.props.templates.length ? this.props.templates : [];

		const list = [];

		if (templates) {
			templates.forEach((template, i) => {
				if (
					template.title.toLowerCase().includes(this.state.name.toLowerCase()) &&
					template.AuthorisationMode !== ApiAuthorisationModes.Public
				) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setTemplateToEdit(template);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{template.title}
						</MenuItem>,
					);
				}
			});
		}
		return list;
	};

	setTemplateToEdit = (template) => {
		this.setState({
			AuthorisationMode: template.AuthorisationMode,
			selectedTemplate: template,
			name: template.title,
			selectedTemplateId: template.id,
			selectedImageSrc: template.imageSrc,
			selectedImageMobileSrc: template.imageMobileSrc,
			selectedCategories: template.categories,
			tenants: template.tenants ? template.tenants : [],
			selectTenants: template.AuthorisationMode === ApiAuthorisationModes.Organization && template.tenants.length,
		});
	};

	unsetTemplate = () => {
		this.setState({
			selectedTemplate: undefined,
			name: '',
			contentId: null,
			tenants: [],
			AuthorisationMode: TemplateAccessLevel[0].value,
		});
	};

	overrideTemplate = () => {
		let templates = this.props.templates.length ? this.props.templates : [];

		/* istanbul ignore next */
		let successCb = () => {
			this.setState(this.getInitialState());
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.TEMPLATE_SAVED });
			useModalStore.getState().closeModal(ModalTypes.TEMPLATE_MODAL);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.TEMPLATE_NOT_SAVED });
		};

		/* istanbul ignore next */
		let limitErrorCb = () => {
			this.setState({ loading: false });
			showNotification({
				type: NotificationTypes.ERROR,
				text: ErrorMessages.TEMPLATE_NOT_SAVED_LIMIT_EXCEDED.replace(
					'{template_limit}',
					config.template_market.market_private_templates_limit.toString(),
				),
			});
		};

		let limit = templates.filter((template) => template.AuthorisationMode === ApiAuthorisationModes.Private).length;

		const contentStore = useContentStore.getState();

		const categoryToUse = this.state.categories ? this.state.categories : this.state.selectedTemplate.categories.join(', ');
		let categories =
			(categoryToUse &&
				categoryToUse
					.trim()
					.split(',')
					.map((cat) => cat.trim())) ||
			[];

		this.setState({ loading: true });

		const userData = getUser();

		this.props.saveNewTemplate(
			{
				title: this.state.name.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''),
				id: this.state.selectedTemplateId,
				categories: categories,
				imageSrc: this.state.selectedImageSrc,
				imageMobileSrc: this.state.selectedImageMobileSrc,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				tenants: this.state.tenants,
				json: contentStore.content,
			},
			limit,
			this.state.AuthorisationMode,
			successCb,
			limitErrorCb,
			errorCb,
		);
	};
}

const TemplateModalWrapper = (props) => {
	const templateProps = useTemplateStore();
	const { allowProdSave } = useLeftSidebarStore((state) => {
		return {
			allowProdSave: state.allowProdSave,
		};
	}, shallow);
	const templateId = useContentStore((state) => state.content.templateId);
	const availableTenants = useUserStore((state) => state.tenants);

	return (
		<TemplateModal
			{...templateProps}
			contentId={templateId}
			templates={templateProps.templatesMetadata}
			allowProdSave={allowProdSave}
			availableTenants={availableTenants}
			template={props.data?.template}
			{...props}
		/>
	);
};

export default TemplateModalWrapper;
