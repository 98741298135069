import { SimpleGrid } from '@chakra-ui/react';
import { AllComponentTypes, ComponentDefaults, FieldComponentNames, FieldComponentTypes } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import { showNotification } from '@/stores/NotificationStore';

import { findRow } from '@/util/helper';
import { ElementLabels, ElementMenuIcons, NotificationTypes, SlotsNotAllowedForElementDesigner } from '@/util/resources';

import { Element } from '@/components/gui/content/helper/AddElementsBox.react';

export const _elementItems = Object.keys(FieldComponentTypes)
	.filter((item) => item !== FieldComponentTypes.submit_button)
	.map((comp) => {
		return {
			label: ElementLabels[comp],
			iconSrc: ElementMenuIcons[comp],
			type: FieldComponentTypes[comp],
		};
	});

const FieldPlaceholderSettings = ({ address, lastPage, pageIndex, uniqueId, id }) => {
	const contentRows = useContentStore((state) => state.content.rows);
	const generalSettings = useContentStore((state) => state.general_settings.components);
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const slotType = findRow(contentRows, address.rowId).row.slots[address.slot].type;

	const onClick = (type) => () => {
		if (type === AllComponentTypes.recaptcha && lastPage !== pageIndex) {
			showNotification({
				type: NotificationTypes.WARNING,
				text: 'reCAPTCHA should be always added at the last page of your form.',
			});
			return;
		}

		onChange({
			...ComponentDefaults[type],
			...generalSettings[type],
			name: `${FieldComponentNames[type]}_${uniqueId.substring(0, 5)}`,
			id,
		});
	};

	const onChange = (settings) => {
		if (address) {
			saveElementSettings({
				address,
				settings,
			});
		}
	};

	const elements = _elementItems
		.filter((item) => {
			return !SlotsNotAllowedForElementDesigner[item.type].includes(slotType) && item.type in AllComponentTypes;
		})
		.map((item, key) => {
			return <Element {...item} key={key} onClick={onClick} />;
		});

	return (
		<SimpleGrid columns={2} spacing={2}>
			{elements}
		</SimpleGrid>
	);
};

export default FieldPlaceholderSettings;
