import React from 'react';

import { AllComponentTypes, ComponentDefaults, getShadowValFromProps } from 'shared/src/utils/shared.js';

class Spacer extends React.PureComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.spacer] };

	render() {
		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';
		return <div className={`component global-spacer spacer-component spacer${id} `} data-component-type="spacer" id={id} />;
	}
}

export const generateSpacerClass = (component) => {
	return `
        .spacer${component.id.replace(/-/g, '')} {
            background-color: ${component.background_color || 'transparent'};
            border-width: ${component.borderWidth}px;
            border-radius: ${component.borderRadius}px;
            border-color: ${component.borderColor};
            border-style: ${component.borderWidth ? 'solid' : 'unset'};
            height: ${component.height}px;
            box-shadow: ${getShadowValFromProps(component.box_shadow)};
        }
    `;
};

export default Spacer;
