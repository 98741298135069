import { Box, Card, CardBody, Image, Text } from '@chakra-ui/react';

import { getUser } from '@/util/auth/auth';
import { ApiAuthorisationModes } from '@/util/resources';

import MoreMenu from '@/components/gui/shared/MoreMenu';

export const DraggableBoxItem = ({ className, iconSrc, onShare, onRemove, labels = [], boxProps = {}, authorisationMode, createdBy }) => {
	const userData = getUser();
	const canEdit = !createdBy || createdBy.id === userData.sub;
	const showMoreMenu = authorisationMode !== ApiAuthorisationModes.Public && canEdit;

	return (
		<>
			<Card variant="flat" size="sm" layerStyle="interactive.raise" {...boxProps} h="20" cursor="move" className={className}>
				<CardBody display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" position="relative" gap="2">
					<Image src={iconSrc} boxSize="6" />
					{labels.filter(Boolean)?.length &&
						labels.filter(Boolean).map((label) => (
							<Text
								key={label}
								display="block"
								w="100%"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
								title={label}
								textAlign="center"
								fontSize="xs"
							>
								{label}
							</Text>
						))}

					{showMoreMenu && (onShare || onRemove) && (
						<Box position="absolute" top={1} right={1}>
							<MoreMenu
								size="xs"
								actions={[
									{ label: 'Share', action: onShare, noRender: !onShare },
									{ label: 'Delete', action: onRemove, testId: 'draggable-box-item-remove-btn', noRender: !onRemove },
								]}
								menuProps={{ strategy: 'fixed' }}
								menuListProps={{ zIndex: 'popover' }}
							/>
						</Box>
					)}
				</CardBody>
			</Card>
		</>
	);
};
