import { useEffect } from 'react';

import { ErrorMessages, ModalMessages, SuccessMessages } from 'config/messages';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FieldComponentNames, FieldComponentTypes, generateGuid } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { findElementAddressById } from '@/util/helper';
import itemTypes from '@/util/itemTypes';
import { Modals, NotificationTypes } from '@/util/resources';

import { DraggableBoxItem } from './DraggableBoxItem';

const DraggableElement = ({
	connectDragPreview,
	connectDragSource,
	elementId,
	onDoubleClick,
	className,
	iconSrc,
	isCustomElement,
	label,
	authorisationMode,
	createdBy,
	item,
}) => {
	const removeCustomElement = () => {
		const successCb = () => {
			useLeftSidebarStore.getState().getCustomElements();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ELEMENT_REMOVED });
		};

		const errorCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ELEMENT_NOT_REMOVED });
		};

		const onOk = () => elementId && useLeftSidebarStore.getState().removeCustomElement(elementId, successCb, errorCb);

		useModalStore.getState().showGeneralModal({
			modal: Modals.ERROR,
			title: 'Delete custom item?',
			message: ModalMessages.REMOVE_CUSTOM_ELEMENT,
			okLabel: 'Delete',
			onOk,
		});
	};

	const shareCustomElement = () => {
		const onError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Could not get saved item data' });
		};

		const onSuccess = (element) => {
			console.log(element, item);
			useLeftSidebarStore.getState().setCustomElementToSave(element);
			useModalStore.getState().showCustomElementModal({ element: item });
		};

		useLeftSidebarStore.getState().getCustomElementData(item, onSuccess, onError);
	};

	useEffect(() => {
		connectDragPreview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	return connectDragSource(
		<div onDoubleClick={onDoubleClick} role="group">
			<DraggableBoxItem
				iconSrc={iconSrc}
				onShare={isCustomElement && shareCustomElement}
				onRemove={isCustomElement && removeCustomElement}
				labels={[label]}
				className={className}
				boxProps={{ 'data-testid': 'draggable-element' }}
				authorisationMode={authorisationMode}
				createdBy={createdBy}
			/>
		</div>,
	);
};

let undoRefreshInterval;

/* istanbul ignore next */
const draggableFunctions = {
	beginDrag(props) {
		const contentStore = useContentStore.getState();

		contentStore.toggleDragging();

		const id = generateGuid();

		return {
			id,
			type: props.type,
			isCustomElement: props.isCustomElement,
		};
	},

	endDrag(props, monitor) {
		const contentStore = useContentStore.getState();
		const id = monitor.getItem().id;

		clearInterval(undoRefreshInterval);
		contentStore.toggleDragging();
		if (!monitor.didDrop()) {
			const address = findElementAddressById(contentStore.content.rows, id);
			if (address) {
				contentStore.removeComponent(address);
			}
		} else if (props.isCustomElement) {
			const address = findElementAddressById(contentStore.content.rows, id);

			const customElements = useLeftSidebarStore.getState().custom_elements;
			const element =
				customElements &&
				customElements.elements &&
				customElements.elements.find((item) => {
					return item.id === props.elementId;
				});

			const onError = () => {
				contentStore.removeComponent(address);
			};

			const onSuccess = (element) => {
				if (FieldComponentTypes[element.type]) {
					element.name = `${FieldComponentNames[element.type]}_${element.uniqueId.substring(0, 5)}`;
				}

				contentStore.saveElementSettings({ address, settings: { ...element, loading: false } });
			};

			if (element) useLeftSidebarStore.getState().getCustomElementData(element, onSuccess, onError);
		}
	},
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	};
}

export default DragSource(itemTypes.ELEMENT, draggableFunctions, collect)(DraggableElement);
