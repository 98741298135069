import React, { createRef } from 'react';

import { Box, Button, FormControl, FormLabel, Input, Menu, MenuItem, MenuList, ModalBody, ModalFooter, VStack } from '@chakra-ui/react';
import config from 'config/config';
import { ErrorMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { shallow } from 'zustand/shallow';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';
import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { leadingDebounce } from '@/util/helper';
import { ApiAuthorisationModes, ModalTypes, NotificationTypes, PublicOptions } from '@/util/resources';

import EnvironmentSelection from '@/components/gui/shared/EnvironmentSelection';
import Modal from '@/components/gui/shared/modals/Modal.react';

export class CustomRowModal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();
		this.state = this.getInitialState(props);
	}

	componentDidUpdate(prevProps) {
		if (this.props.row !== prevProps.row) {
			this.setState({
				title: this.props.row?.title || '',
				rowToEdit: this.props.row,
				authorisationMode: this.props.row?.authorisationMode || ApiAuthorisationModes.Private,
				tenants: this.props.row?.tenants || [],
				selectTenants: this.props.row?.authorisationMode === ApiAuthorisationModes.Organization && this.props.row?.tenants.length,
			});
		}
	}

	render() {
		const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test' || this.props.allowProdSave;

		const dropDown = this.state.title && !this.state.rowToEdit && this.renderDropDown();

		const invalidName = !this.state.title;
		const invalidTenants =
			this.state.authorisationMode === ApiAuthorisationModes.Organization && this.state.selectTenants && !this.state.tenants.length;

		return (
			<Modal
				display={this.props.show}
				title={this.props.row ? 'Share layout' : 'Save as layout'}
				modalType={ModalTypes.CUSTOM_ROW_MODAL}
				size="md"
			>
				<ModalBody>
					<VStack spacing={4}>
						<FormControl>
							<FormLabel>Layout name</FormLabel>
							<Input
								ref={this.nameInputRef}
								maxLength={40}
								value={this.state.title}
								readOnly={this.state.rowToEdit}
								onChange={(e) => this.setState({ title: e.target.value })}
								data-testid="layout-name-input"
							/>
							<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
								{dropDown}
							</Box>
						</FormControl>
						{isDev && (
							<React.Fragment>
								<FormControl>
									<FormLabel>Layout order</FormLabel>
									<Input maxLength={40} value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} />
								</FormControl>
							</React.Fragment>
						)}
						<EnvironmentSelection
							label="Save layout in"
							authMode={this.state.authorisationMode}
							tenants={this.state.tenants}
							availableTenants={this.props.availableTenants}
							selectTenants={this.state.selectTenants}
							onChangeAuthMode={(value) => this.setState({ authorisationMode: value })}
							onChangeTenants={(value) => this.setState({ tenants: value })}
							onChangeSelectTenants={(value) => this.setState({ selectTenants: value })}
						/>
					</VStack>
				</ModalBody>
				<ModalFooter>
					{this.state.rowToEdit ? (
						<Box display="flex" justifyContent="flex-end">
							{!this.props.row && !this.state.loading && (
								<Button variant="ghost" onClick={this.unsetRow} isDisabled={this.state.loading}>
									Cancel
								</Button>
							)}
							<Button onClick={this.replaceRow} isDisabled={this.state.loading}>
								{this.props.row ? 'Share layout' : 'Overwrite existing'}
							</Button>
						</Box>
					) : (
						<Box display="flex" justifyContent="flex-end">
							<Button
								data-testid="save-layout-button"
								onClick={this.saveRow}
								isDisabled={this.state.loading || invalidName || invalidTenants}
							>
								Save
							</Button>
						</Box>
					)}
				</ModalFooter>
			</Modal>
		);
	}

	getInitialState = () => ({
		authorisationMode: ApiAuthorisationModes.Private,
		title: this.props.row?.title || '',
		hoverItem: -1,
		rowToEdit: this.props.row,
		order: '',
		loading: false,
		tenants: [],
		selectTenants: false,
	});

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	saveRow = leadingDebounce(() => {
		if (!this.state.title) {
			showNotification({ type: NotificationTypes.WARNING, text: WarningMessages.CUSTOM_ROW_NO_TITLE });
			return;
		}

		/* istanbul ignore next */
		let successCb = () => {
			this.setState(this.getInitialState());
			this.props.getCustomRows();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ROW_SAVED });
			useModalStore.getState().closeModal(ModalTypes.CUSTOM_ROW_MODAL);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ROW_NOT_SAVED });
		};

		this.setState({ loading: true });

		const userData = getUser();

		if (this.props.row_to_save) {
			this.props.saveCustomRow(
				{
					...this.props.row_to_save,
					tenants: this.state.tenants,
					createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				},
				this.state.title,
				'',
				this.state.order,
				this.state.authorisationMode,
				successCb,
				errorCb,
			);
		}
	}, config.debounce_times.general);

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let rows = this.props.rows;

		const list = [];

		const filteredRows = this.props.allowProdSave
			? rows
			: rows.filter((row) => {
					return row.authorisationMode === ApiAuthorisationModes.Private;
				});

		if (filteredRows) {
			filteredRows.forEach((row, i) => {
				if (row.title.toLowerCase().includes(this.state.title.toLowerCase())) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setRowToEdit(row);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{row.title}
						</MenuItem>,
					);
				}
			});
		}

		return list;
	};

	setRowToEdit = (row) => {
		this.setState({
			rowToEdit: row,
			title: row.title,
			order: row.order,
			authorisationMode: row.authorisationMode,
			tenants: row.tenants,
			createdBy: row.createdBy,
			selectTenants: row.authorisationMode === ApiAuthorisationModes.Organization && row.tenants.length,
		});
	};

	unsetRow = () => {
		this.setState({ rowToEdit: undefined, title: '' });
	};

	replaceRow = () => {
		const row = this.state.rowToEdit;

		/* istanbul ignore next */
		const saveError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Unknown error occurred' });
		};

		/* istanbul ignore next */
		const saveSuccess = () => {
			this.setState(this.getInitialState());
			this.props.getCustomRows();
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Successfully overwritten.' });
			this.unsetRow();
			useModalStore.getState().closeModal(ModalTypes.CUSTOM_ROW_MODAL);
		};

		const userData = getUser();

		/* istanbul ignore next */
		this.props.saveCustomRow(
			{
				...this.props.row_to_save,
				tenants: this.state.tenants,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
			},
			this.state.title,
			row.id,
			this.state.order,
			this.state.authorisationMode,
			saveSuccess,
			saveError,
		);
	};
}

const CustomRowModalWrapper = (props) => {
	const { custom_rows, getCustomRows, saveCustomRow } = useLeftSidebarStore((state) => {
		return {
			custom_rows: state.custom_rows,
			getCustomRows: state.getCustomRows,
			saveCustomRow: state.saveCustomRow,
		};
	}, shallow);
	const availableTenants = useUserStore((state) => state.tenants);

	return (
		<CustomRowModal
			{...custom_rows}
			getCustomRows={getCustomRows}
			saveCustomRow={saveCustomRow}
			{...props}
			availableTenants={availableTenants}
			row={props.data?.row}
		/>
	);
};

export default CustomRowModalWrapper;
