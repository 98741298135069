import { Children, cloneElement, memo, ReactElement, useCallback, useMemo } from 'react';

import { Box, Card, Grid, GridItem } from '@chakra-ui/react';
import { generateGuid } from 'shared/src/utils/shared.js';
import { useDeepCompareCallback } from 'use-deep-compare';
import { useShallow } from 'zustand/react/shallow';

import useContentStore from '@/stores/ContentStore';

import { ComponentTypeToComparer, ConditionalLogicTypes, FieldsNotAllowedAsCondition, LogicErrors } from '@/util/resources';

import MoreMenu from '@/components/gui/shared/MoreMenu';
import { MultiSelectWithTags } from '@/components/gui/shared/MultiSelectWithTags';

import { useLogicMenuActions } from './hooks/useMenuActions';
import { useParentSettings } from './hooks/useParentSettings';
import { ConditionComponentCommonProps, ConditionsCommonProps, CriteriaProps, IConditionField } from './models/model';
import { Operator } from './Operator';
import { createBaseConditionGroup } from './utils';

export interface IConditionProps extends ConditionComponentCommonProps, ConditionsCommonProps {
	type: string;
	id: string;
	operator: string;
	field?: IConditionField;
	settings: CriteriaProps[];
	children: ReactElement;
}

const Condition = ({
	parentAddress: propAddress,
	index,
	id,
	operator,
	type,
	settings,
	field,
	isLast,
	isGrouped,
	isFirst,
	children,
}: IConditionProps) => {
	const { parentAddress, address, getParentSettings, errors } = useParentSettings(propAddress, index);

	const { changeConditionSetting, canBeModified, allFields } = useContentStore(
		useShallow((state) => ({
			changeConditionSetting: state.changeConditionSetting,
			canBeModified: state.canBeModified,
			allFields: state.allFields.filter((item: IConditionField) => !FieldsNotAllowedAsCondition.includes(item.type)) as IConditionField[],
		})),
	);

	const { handleDuplicate, handleMove, handleDelete } = useLogicMenuActions(index, parentAddress, getParentSettings, {
		type,
		operator,
		settings,
		field,
	});

	const onGroupClick = useDeepCompareCallback(() => {
		const parentSettings = [...getParentSettings()];
		const group = createBaseConditionGroup([
			{
				id: id,
				operator: operator,
				type: type,
				settings: settings,
				field: field,
			},
		]);

		parentSettings.splice(index, 1, group);

		changeConditionSetting(parentAddress, { settings: parentSettings });
	}, [field, id, index, operator, parentAddress, settings, type]);

	const onChangeField = useCallback(
		(type: string, value: string) => {
			const field = allFields.find((item) => item.id === value);

			if (field) {
				changeConditionSetting(address, {
					[type]: field,
					settings: [
						{
							id: generateGuid(),
							type: ConditionalLogicTypes.criteria,
							value: '',
							comparer: ComponentTypeToComparer[field.type][0],
						},
					],
				});
			}
		},
		[address, allFields],
	);

	const options = useMemo(() => {
		if (!allFields || !allFields.length) {
			return [];
		}

		return allFields.map((field) => ({
			title: field.label,
			subtitle: field.name,
			value: field.id,
			group: `Page ${field.page + 1}`,
		}));
	}, [allFields]);

	const availableActions = [
		{
			action: () => handleMove('up'),
			label: 'Move up',
			noRender: isFirst,
		},
		{
			action: () => handleMove('down'),
			label: 'Move down',
			noRender: isLast,
		},
		{
			action: handleDuplicate,
			label: 'Duplicate',
		},
		{
			action: handleDelete,
			label: 'Delete',
		},
		{
			action: onGroupClick,
			label: 'Create group',
			noRender: isGrouped,
		},
	];

	const renderChildren = () => {
		return Children.map(children, (child) => {
			return cloneElement(child, {
				parentAddress: address,
				key: child.props.id,
			});
		});
	};

	return (
		<>
			<Card variant="flat" display="flex" direction="row" p={2.5}>
				<Grid w="100%" templateColumns="16.66% 16.66% 1fr auto" gap={2}>
					<GridItem>
						<MultiSelectWithTags
							value={field ? field.id : ''}
							options={options}
							onChange={(data) => onChangeField('field', data as string)}
							disabled={!canBeModified}
							optionDisplayCb={(option) => option.title || option.subtitle}
							hasError={
								errors.includes(LogicErrors.invalidField) ||
								errors.includes(LogicErrors.missingName) ||
								errors.includes(LogicErrors.missingId)
							}
						/>
					</GridItem>

					{renderChildren()}
				</Grid>

				<Box display="flex" p={1}>
					<MoreMenu actions={availableActions} disabled={!canBeModified} dataTestId="condition-more-menu" />
				</Box>
			</Card>

			{operator && !isLast && <Operator value={operator} address={address} disabled={!canBeModified} />}
		</>
	);
};

export default memo(Condition);
