import { Button, ButtonGroup, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, VStack } from '@chakra-ui/react';
import { MultiValue, Select } from 'chakra-react-select';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { ApiAuthorisationModes, TemplateAccessLevel } from '@/util/resources';

type Tenant = {
	id: string;
	name: string;
	displayName: string;
};

type Props = {
	label: string;
	authMode: keyof typeof ApiAuthorisationModes;
	tenants: { value: string; label: string }[];
	availableTenants: Tenant[];
	selectTenants: boolean;
	onChangeAuthMode: (mode: string) => void;
	onChangeTenants: (tenants: MultiValue<{ label: string; value: string }>) => void;
	onChangeSelectTenants: (selectTenants: boolean) => void;
};

const EnvironmentSelection = ({
	label,
	authMode,
	tenants,
	availableTenants,
	selectTenants,
	onChangeAuthMode,
	onChangeTenants,
	onChangeSelectTenants,
}: Props) => {
	return (
		<VStack spacing={4} w="full">
			<FormControl>
				<FormLabel>{label}</FormLabel>
				<Select
					useBasicStyles
					data-testid="access-level-select"
					value={TemplateAccessLevel.find((item) => item.value === authMode)}
					defaultValue={TemplateAccessLevel[0]}
					onChange={(selectedOption) => onChangeAuthMode(selectedOption.value)}
					placeholder="Select an option"
					options={TemplateAccessLevel}
				/>
			</FormControl>

			{authMode === ApiAuthorisationModes.Organization && (
				<FormControl>
					<ButtonGroup variant="toggle" size="sm" layerStyle="toggleGroup">
						<Button
							isActive={!selectTenants}
							onClick={() => {
								onChangeSelectTenants(false);
								onChangeTenants([]);
							}}
						>
							Entire organization
						</Button>
						<Button
							isActive={selectTenants}
							onClick={() => {
								const userData = getUser();
								const currentTenant = availableTenants.find((item) => item.id === userData[claimsKeys.TENANT_ID]);
								const tenants = [];
								if (currentTenant) {
									tenants.push({ value: currentTenant.id, label: currentTenant.displayName });
								}
								onChangeSelectTenants(true);
								onChangeTenants(tenants);
							}}
						>
							Select environments
						</Button>
					</ButtonGroup>
				</FormControl>
			)}

			{authMode === ApiAuthorisationModes.Organization && selectTenants && (
				<FormControl isInvalid={!tenants.length}>
					<FormLabel>Environments</FormLabel>
					<Select
						isMulti
						useBasicStyles
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						selectedOptionStyle="check"
						data-testid="access-level-select"
						value={tenants}
						onChange={(options) => onChangeTenants(options)}
						isInvalid={!tenants.length}
						placeholder="Select an option"
						options={availableTenants.map((item) => ({ value: item.id, label: item.displayName }))}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						At least one environment must be selected
					</FormErrorMessage>
				</FormControl>
			)}
		</VStack>
	);
};

export default EnvironmentSelection;
