import React from 'react';

import {
	AllComponentTypes,
	getAutocomplete,
	getPadding,
	getShadowValFromProps,
	getTransformerFormFieldFontStyles,
} from 'shared/src/utils/shared.js';

export const SharedInput = (props) => {
	const { formId } = props;

	const directionClass =
		props.labelPosition === 'row' ? 'lp-flex-direction-horizontal lp-flex-align-center' : 'lp-flex-direction-vertical element-vertical-ie';

	const id = props.id ? props.id.toString().replace(/-/g, '') : '';
	const labelId = `${id}-label`;

	const autocomplete = getAutocomplete(props.type, props.autocomplete);

	let Input = (
		<input
			id={`${props.type}-${id}`}
			data-id={id}
			className={`lp-flex-1 global-input-element form-input-element form-input-wrapper params-${props.type} `}
			data-type={props.type}
			data-label={props.label}
			data-required={props.required}
			// data-validate={props.validate || false}
			type={props.inputType}
			placeholder={props.placeholder}
			disabled={props.disabled}
			maxLength={props.maxLength ? props.maxLength : undefined}
			name={props.name}
			value={props.prefill ? props.prefill : props.value}
			required={props.required}
			pattern={props.pattern || undefined}
			max={props.inputType === 'date' ? '9999-12-31' : ''}
			// readOnly={props.readOnly}
			aria-labelledby={labelId}
			{...(autocomplete && { autocomplete })}
		/>
	);

	if (props.variant === 'textarea') {
		Input = (
			<textarea
				data-id={id}
				className={`lp-flex-1 global-input-element form-input-element params-${props.type} `}
				data-type={props.type}
				data-label={props.label}
				data-required={props.required}
				// data-validate={props.validate || false}
				type={props.inputType}
				placeholder={props.placeholder}
				disabled={props.disabled}
				name={props.name}
				value={props.prefill ? props.prefill : props.value}
				rows={4}
				required={props.required}
				pattern={props.pattern || undefined}
				style={{ resize: 'none' }}
				maxLength={props.maxLength ? props.maxLength : undefined}
				// readOnly={props.readOnly}
				aria-labelledby={labelId}
				{...(autocomplete && { autocomplete })}
			/>
		);
	}

	return (
		<div
			id={id}
			className={`component shared-input-field ${props.required ? 'required-component' : ''} ${props.type}-component ${
				props.type
			}${props.id.replace(/-/g, '')}`}
		>
			<label className={`form-input-wrapper-element lp-flex-container form-input-${formId} ${directionClass}`}>
				<span id={labelId} className="global-input-label form-field-label ">
					{!props.labelHide && props.label}
					{!props.descriptionHide && props.description && <span className="a11y-visible">, {props.description},</span>}
					{props.maxLength && <span className="a11y-visible">, Max {props.maxLength} characters,</span>}
					<span className="global-field-required form-field-required " aria-hidden="true">
						{' '}
						*
					</span>
				</span>
				<div className="lp-flex-1 global-input-wrapper form-input-wrapper-div">
					{Input}
					{props.maxLength ? (
						<div className="limit-counter" aria-hidden="true">
							0/{props.maxLength}
						</div>
					) : null}
				</div>
			</label>
			{!props.descriptionHide && props.description && (
				<div className="global-input-description form-input-description  ">{props.description}</div>
			)}
		</div>
	);
};

SharedInput.defaultProps = {
	variant: 'input',
};

export const generateSharedInputClass = (component) => {
	const dynamicContainerStyles =
		component.labelPosition === 'row'
			? `
					align-items: 'center';
					-webkit-box-align: 'center';
					-ms-flex-align: 'center';
			  `
			: ``;

	const labelDimension =
		component.labelPosition === 'row'
			? `
					width: ${component.labelWidth}px;
			  `
			: `
					min-height: ${component.labelWidth}px;
			  `;

	const wrapperStyle = `
		background: ${component.fieldBgColor};
		border-width: ${component.fieldBorderWidth}px;
		border-color: ${component.fieldBorderColour};
		border-radius: ${component.fieldBorderRadius}px;
		box-shadow: ${getShadowValFromProps(component.box_shadow)};
	`;

	const fieldStyle = `
		padding: ${component.fieldPadding}px;
		padding-left: ${component.type === AllComponentTypes.phone_input ? component.fieldPadding + 50 : component.fieldPadding}px;
		color: ${component.fieldFontColour};
		font-size: ${component.fieldFontSize / 16}rem;
		font-weight: ${component.fieldFontWeight};
		font-style: ${component.fieldFontStyle};
		text-decoration: ${component.fieldTextDecoration};
		${getTransformerFormFieldFontStyles(component.fieldFont)}
	`;

	return `
        .${component.type}${component.id.replace(/-/g, '')} {
            width: 100%; 
            position: relative;
            background-color: ${component.background_color};
            display: ${(component.hiddenField || component.hidden) && !component.required ? 'none' : 'block'};
			${getPadding(component)}
			padding-bottom: ${component.padding > 25 ? component.padding : 25}px;
        }

        .${component.type}${component.id.replace(/-/g, '')} label {
            flex-direction: ${component.labelPosition};
            ${dynamicContainerStyles}
        }

        .${component.type}${component.id.replace(/-/g, '')} label .form-field-label {
            color: ${component.labelFontColour};
            font-size: ${component.labelFontSize / 16}rem;
            font-weight: ${component.labelFontWeight};
            font-style: ${component.labelFontStyle};
            text-decoration: ${component.labelTextDecoration};
            display: ${component.labelHide ? 'none' : 'block'};
            ${labelDimension}
            ${getTransformerFormFieldFontStyles(component.labelFont)}
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-field-required {
            color: ${component.asteriskColor};
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-wrapper-div {
			${wrapperStyle}
        }

				.${component.type}${component.id.replace(/-/g, '')} .form-input-wrapper-div:has(.form-input-element:focus) {
					outline: 2px solid #007fff;
				}

        .${component.type}${component.id.replace(/-/g, '')} .form-input-element {
            ${fieldStyle}
        }

		.${component.type}${component.id.replace(/-/g, '')} .limit-counter {
			${fieldStyle}
			align-self: flex-end;
		}

        .${component.type}${component.id.replace(/-/g, '')} .form-input-element::placeholder {
            color: ${component.placeholderFontColour};
            font-size: ${component.fieldFontSize / 16}rem;
            font-weight: ${component.placeholderFontStyle};
            font-style: ${component.placeholderFontWeight};
            text-decoration: ${component.placeholderTextDecoration};
            font-family: ${component.placeholderFont};
        }

        .${component.type}${component.id.replace(/-/g, '')} .form-input-description, .${component.type}${component.id.replace(
					/-/g,
					'',
				)} .form-input-error-message {
            font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
            color: ${component.descriptionFontColour};
            font-family: ${component.descriptionFontFamily};
            font-size: ${component.descriptionFontSize / 16}rem;
            margin-top: ${component.descriptionSpacing}px;
            display: ${component.descriptionHide ? 'none' : 'block'};
			padding-left: ${component.labelPosition === 'row' ? component.labelWidth + 'px' : 'initial'};
        }
    `;
};

export default SharedInput;
