import { memo } from 'react';

import { Modal as ChakraModal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import useModalStore from '@/stores/ModalStore';

import { ModalDesigns, Modals } from '@/util/resources';

const Modal = ({ modal = Modals.CONFIRM, display, modalType, title, closeCallback, size = 'xl', children }) => {
	const label = title || ModalDesigns[modal].title;

	const onClose = () => {
		if (closeCallback) {
			closeCallback();
		} else {
			useModalStore.getState().closeModal(modalType);
		}
	};

	return (
		<ChakraModal isOpen={display} onClose={onClose} size={size}>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>{label}</ModalHeader>

				<ModalCloseButton />

				{children}
			</ModalContent>
		</ChakraModal>
	);
};

export default memo(Modal);
