import React from 'react';

import { Box, Card, CardBody, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import { AllComponentTypes } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import { showNotification } from '@/stores/NotificationStore';

import { formatTestId } from '@/util/helper';
import { ElementLabels, ElementMenuIcons, NotificationTypes, SlotsNotAllowedForElementDesigner } from '@/util/resources';

export const _elementItems = Object.keys(AllComponentTypes).map((comp) => {
	return {
		label: ElementLabels[comp],
		iconSrc: ElementMenuIcons[comp],
		type: AllComponentTypes[comp],
	};
});

export const Element = (props) => {
	return (
		<GridItem w="100%">
			<Card variant="outline" size="sm" layerStyle="interactive.fill" onClick={props.onClick(props.type)} data-testid="add-element-box">
				<CardBody display="flex" alignItems="center" position="relative" gap="3">
					<Image src={props.iconSrc} boxSize="6" />
					{props.label && (
						<Text
							display="block"
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
							title={props.label}
							maxW="100%"
							data-testid={`add-element-box-${formatTestId(props.label)}`}
						>
							{props.label}
						</Text>
					)}
				</CardBody>
			</Card>
		</GridItem>
	);
};

export const ElementsGrid = (props) => {
	return (
		<Box display="flex" flexDirection="column" className="helper-grid-layout" borderStyle="dashed" borderColor="purple.400" borderWidth={3}>
			<Box p={3} bgColor="purple.400" color="chakra-inverse-text" outline="3px solid" outlineColor="purple.400">
				<Heading size="xs">Add item</Heading>
			</Box>
			{/*<Box display="flex" p={5} gap={4} flexWrap="wrap" pr={1.5} pb={1.5} className="helper-grid-element">*/}
			<Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))" gap={3} p={2.5} className="helper-grid-element">
				{props.elements}
				{/* {props.customElements} */}
			</Grid>
		</Box>
	);
};

const AddElementsBox = ({ pageIndex, address, position, type, customElements }) => {
	const onClick = (type) => {
		return () => {
			const contentStore = useContentStore.getState();

			const limitedComponents = contentStore.getLimitedComponents()[pageIndex];

			if (limitedComponents && limitedComponents.find((item) => item.type === type)) {
				showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
				return;
			}

			let insertAddress = { ...address, component: position };

			contentStore.insertComponentByHelper({
				insertAddress,
				componentData: { type },
				additionalData: { componentType: type, pageIndex: pageIndex },
			});
			contentStore.deactivateAddElementBoxHelper();
		};
	};

	// const onClickCustom = (element) => {
	// 	return () => {
	// 		const contentStore = useContentStore.getState();

	// 		const limitedComponents = contentStore.getLimitedComponents()[pageIndex];

	// 		if (limitedComponents && limitedComponents.find((item) => item.type === element.type)) {
	// 			showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
	// 			return;
	// 		}

	// 		let insertAddress = { ...address, component: position };

	// 		contentStore.insertComponentByHelper({
	// 			insertAddress,
	// 			componentData: { type: element.type, loading: true },
	// 			additionalData: { componentType: type, pageIndex: pageIndex },
	// 		});
	// 		contentStore.deactivateAddElementBoxHelper();

	// 		/* istanbul ignore next */
	// 		const onError = () => {
	// 			contentStore.removeComponent(insertAddress);
	// 		};

	// 		/* istanbul ignore next */
	// 		const onSuccess = (rsp) => {
	// 			const newElement = JSON.parse(rsp);
	// 			contentStore.saveElementSettings({ address: insertAddress, settings: { ...newElement, loading: false } });
	// 		};

	// 		useLeftSidebarStore.getState().getCustomElementData(element, onSuccess, onError);
	// 	};
	// };

	const slotsNotAllowed = SlotsNotAllowedForElementDesigner;

	let elements = _elementItems
		.filter((item) => {
			return !slotsNotAllowed[item.type].includes(type) && item.type in AllComponentTypes;
			//filter out the components that are not allowed in certain slot types
		})
		.sort((itemA, itemB) => {
			const textA = itemA.label.toUpperCase();
			const textB = itemB.label.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		})
		.map((item, key) => {
			return <Element {...item} key={key} onClick={onClick} />;
		});

	// const hasCustomElements = customElements && customElements.elements && customElements.elements.length;

	// const customElements = hasCustomElements
	// 	? customElements.elements
	// 			.filter((item) => {
	// 				return item.authorisationMode === ApiAuthorisationModes.Private;
	// 			})
	// 			.map((item, key) => {
	// 				const iconSrc = ElementMenuIcons[item.type];
	// 				return <Element {...item} iconSrc={iconSrc} label={item.title} key={key} onClick={() => onClickCustom(item)} />;
	// 			})
	// 	: null;

	return <ElementsGrid elements={elements} customElements={customElements} />;
};

export default AddElementsBox;
