import config from 'config/config';
import { isEmpty, uniqWith } from 'lodash';
import Moment from 'moment';
import { generateGuid } from 'shared/src/utils/shared.js';
import { create } from 'zustand';

import useModalStore from '@/stores/ModalStore';

import { transform } from '@/util/apiUtils/entities';
import {
	createHtmlImage,
	deleteFavouriteTemplate,
	deleteTemplate,
	getTemplate,
	getTemplates,
	getTemplatesFromRsp,
	postFavouriteTemplate,
	postTemplate,
	transferTemplate,
	transferTemplateViaEmail,
} from '@/util/apiUtils/template';
import { claimsKeys, getUser } from '@/util/auth/auth';
import { updateTemplateJson } from '@/util/helper';
import { ApiAuthorisationModes } from '@/util/resources';

export const defaultState = {
	page: 1,
	last_page: false,
	currentTemplate: null,
	favouriteTemplates: [],
	search_term: '',
	currentCategory: 'All categories',
	currentPersonalFilter: 0,
	templateCategories: ['All categories'],
	templatesMetadata: [],
	availablePersonalFilters: [
		{
			id: 0,
			label: 'All templates',
		},
		{
			id: 1,
			label: 'Favorites',
		},
		{
			id: 2,
			label: 'Saved',
		},
		{
			id: 3,
			label: 'Public',
		},
		{
			id: 4,
			label: 'Shared',
		},
	],
};

const useTemplateStore = create((set, get) => ({
	...defaultState,
	resetState: () =>
		set(() => {
			return { ...defaultState };
		}),
	setFavouriteTemplates: (templateIDs) => set(() => ({ favouriteTemplates: templateIDs })),
	addSearchTerm: (value) => set(() => ({ search_term: value })),
	selectPersonalFilter: (filterId) => set(() => ({ currentPersonalFilter: filterId })),
	selectCategory: (category) => set(() => ({ currentCategory: category })),
	removeFavouriteTemplate: async (templateID, successCb, errorCb) => {
		await deleteFavouriteTemplate(templateID)
			.then((response) => {
				if (successCb) successCb(response);
				return set((state) => {
					const favouriteTemplates = [...state.favouriteTemplates];
					let templateIndex = favouriteTemplates.indexOf(templateID);
					favouriteTemplates.splice(templateIndex, 1);
					return { favouriteTemplates };
				});
			})
			.catch((error) => {
				console.error(error, true);
				if (errorCb) errorCb(error);
			});
	},
	addFavouriteTemplate: async (templateID, successCb, errorCb) => {
		await postFavouriteTemplate(templateID)
			.then((response) => {
				// dispatch(templateID);
				if (successCb) successCb(response);
				return set((state) => {
					const favouriteTemplates = [...state.favouriteTemplates];
					favouriteTemplates.push(templateID);
					return { favouriteTemplates };
				});
			})
			.catch((error) => {
				console.error(error.toString(), true);
				if (errorCb) errorCb(error);
			});
	},
	saveNewTemplate: async (template, limit, AuthorisationMode = ApiAuthorisationModes.Private, successCb, limitErrorCb, errorCb) => {
		let id = template.id ? template.id : generateGuid();
		let categories = template.categories ? template.categories : [config.template_market.market_default_category_name];

		if (AuthorisationMode === ApiAuthorisationModes.Public && categories.includes(config.template_market.market_default_category_name)) {
			if (errorCb) errorCb();
			console.error('Public templates can not have category "Custom"', true);
			return;
		}

		if (AuthorisationMode === ApiAuthorisationModes.Private && limit >= config.template_market.market_private_templates_limit) {
			//check private templates count limit
			if (limitErrorCb) limitErrorCb();
			return;
		}

		let decoratedTemplate = {
			...template,
			categories,
			id,
			dateSaved: Moment().format('X'),
		};

		try {
			const structureWidth = template.json.structureWidth;

			const transformData = await transform(JSON.stringify({ content: template.json }));
			const templateImageRsp = await createHtmlImage(transformData, structureWidth);

			if (templateImageRsp && templateImageRsp.data) {
				decoratedTemplate = {
					...decoratedTemplate,
					generatedImage: templateImageRsp.data,
				};
			}

			await postTemplate(decoratedTemplate, AuthorisationMode);

			//get templates from api so that templates in the market are 'reset' and we can see the new template
			const getTemplatesRsp = await getTemplates();

			get().loadTemplatesMetadata([...getTemplatesFromRsp(getTemplatesRsp), decoratedTemplate]);

			if (successCb) successCb(id);
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	changeAccessLevel: async (template, AuthorisationMode, successCb, errorCb) => {
		try {
			const response = await getTemplate(template);

			let templateJson = JSON.parse(response.data.Content.Data);

			const decoratedTemplate = {
				...template,
				json: templateJson.json,
				dateSaved: Moment().format('X'),
			};

			await postTemplate(decoratedTemplate, AuthorisationMode);

			//get templates from api so that templates in the market are 'reset' and we can see the new template
			const getTemplatesRsp = await getTemplates();

			get().loadTemplatesMetadata([...getTemplatesFromRsp(getTemplatesRsp), decoratedTemplate]);

			if (successCb) successCb();
		} catch (e) {
			console.error(e);
			if (errorCb) errorCb();
		}
	},
	transferTemplate: async (template) => {
		try {
			const response = await getTemplate(template);

			let templateJson = JSON.parse(response.data.Content.Data);

			const newTemplate = {
				title: templateJson.title,
				json: templateJson.json,
				mobile: templateJson.mobile,
				imageMobileSrc: templateJson.imageMobileSrc,
				generatedImage: templateJson.generatedImage,
				imageSrc: templateJson.imageSrc,
			};

			let decoratedTemplate = {
				...newTemplate,
				categories: template.categories,
				id: generateGuid(),
				dateSaved: Moment().format('X'),
			};

			transferTemplate(decoratedTemplate);
		} catch (e) {
			console.error(e);
		}
	},
	removeTemplate: async (template, successCb, errorCb) => {
		if (template.id) {
			await deleteTemplate(template.id)
				.then((data) => {
					if (successCb) successCb(data);
					return set((state) => {
						const templatesMetadata = [...state.templatesMetadata];
						let templateIndex = templatesMetadata.indexOf(template);
						templatesMetadata.splice(templateIndex, 1);
						return { templatesMetadata };
					});
				})
				.catch((error) => {
					console.error(error, true);
					if (errorCb) errorCb(error);
				});
		}
	},
	//if templateMetadata is provided then this function will call the api
	showTemplate: async (template, templateMetadata, successCb, errorCb) => {
		const tMetadata = templateMetadata;

		let finalTemplate;

		if (tMetadata) {
			finalTemplate = { html: '' };
			useModalStore.getState().showTemplatePreviewModal({ show: true, template: finalTemplate });

			try {
				const response = await getTemplate(tMetadata);

				if (response.data.Content) {
					let template = JSON.parse(response.data.Content.Data);

					const TemplatedContent = await transform(
						JSON.stringify({ content: updateTemplateJson(template.json, template.mobile) }),
						[],
						true,
					);

					finalTemplate = { ...template, html: TemplatedContent };
					if (successCb) successCb(response);
				}
			} catch (error) {
				console.error(error, true);
				if (errorCb) errorCb(error);
			}
		}

		if (isEmpty(finalTemplate)) {
			return;
		}

		useModalStore.getState().showTemplatePreviewModal({ show: true, template: finalTemplate });
		return set(() => ({ currentTemplate: template }));
	},
	transferTemplateViaEmail: async (data, successCb, errorCb) => {
		try {
			await transferTemplateViaEmail(data);
			if (successCb) successCb();
		} catch (error) {
			console.error(error, true);
			if (errorCb) errorCb(error);
		}
	},
	loadTemplatesMetadata: (templates) => {
		const userData = getUser();
		const filteredTemplates = [];

		const uniqueTemplates = uniqWith(templates, (template1, template2) => template1.id === template2.id);

		uniqueTemplates.forEach((item) => {
			if (item.tenants && item.tenants.length) {
				const tenantsIds = item.tenants.map((item) => item.value);
				if (tenantsIds.includes(userData[claimsKeys.TENANT_ID])) {
					filteredTemplates.push(item);
				}
				if (item.createdBy && item.createdBy.tenantId === userData[claimsKeys.TENANT_ID]) {
					filteredTemplates.push(item);
				}
			} else {
				filteredTemplates.push(item);
			}
		});

		set(() => ({
			templatesMetadata: uniqWith(filteredTemplates, (template1, template2) => template1.id === template2.id),
			page: 1,
			last_page: false,
		}));
	},
	loadTemplateCategories: (categories) => set((state) => ({ templateCategories: [...state.templateCategories, ...categories] })),
}));

export default useTemplateStore;

//helpers
// const getTemplateById = (id) => {
// 	let index = 0;
// 	let template = this.state.templatesMetadata.find((temp, key) => {
// 		if (temp.id === id) {
// 			index = key;
// 		}
// 		return temp.id === id;
// 	});

// 	return {
// 		template,
// 		index,
// 	};
// };

// const getPersonalFilterById = (id) => {
// 	let index = 0;
// 	let filter = this.state.availablePersonalFilters.find((filter, key) => {
// 		if (filter.id === id) {
// 			index = key;
// 		}
// 		return filter.id === id;
// 	});

// 	return {
// 		filter,
// 		index,
// 	};
// };
