import React, { createRef } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuItem,
	MenuList,
	ModalBody,
	ModalFooter,
	VStack,
} from '@chakra-ui/react';
import config from 'config/config';
import { ErrorMessages, SuccessMessages, WarningMessages } from 'config/messages';
import { shallow } from 'zustand/shallow';

import useLeftSidebarStore from '@/stores/LeftSidebarStore';
import useModalStore from '@/stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';
import useUserStore from '@/stores/UserStore';

import { claimsKeys, getUser } from '@/util/auth/auth';
import { leadingDebounce } from '@/util/helper';
import { ApiAuthorisationModes, ModalTypes, NotificationTypes, PublicOptions } from '@/util/resources';

import EnvironmentSelection from '@/components/gui/shared/EnvironmentSelection';
import Modal from '@/components/gui/shared/modals/Modal.react';

const initialState = {
	authorisationMode: PublicOptions[0].value,
	title: '',
	hoverItem: -1,
	elementToEdit: undefined,
	order: '',
	loading: false,
	tenants: [],
	selectTenants: false,
};

export class CustomElementModal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nameInputRef = createRef();

		this.state = { ...initialState };
	}

	componentDidUpdate(prevProps) {
		if (this.props.element !== prevProps.element) {
			this.setState({
				title: this.props.element?.title || '',
				elementToEdit: this.props.element,
				authorisationMode: this.props.element?.authorisationMode || ApiAuthorisationModes.Private,
				tenants: this.props.element?.tenants || [],
				selectTenants: this.props.element?.authorisationMode === ApiAuthorisationModes.Organization && this.props.element?.tenants.length,
			});
		}
	}

	render() {
		const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test' || this.props.allowProdSave;
		const dropdown = this.state.title && !this.state.elementToEdit && this.renderDropDown();

		const invalidName = !this.state.title;
		const invalidTenants =
			this.state.authorisationMode === ApiAuthorisationModes.Organization && this.state.selectTenants && !this.state.tenants.length;

		return (
			<Modal
				display={this.props.show}
				title={this.props.element ? 'Share item' : 'Save as item'}
				modalType={ModalTypes.CUSTOM_ELEMENT_MODAL}
				size="md"
			>
				<ModalBody>
					<VStack spacing={4}>
						<FormControl>
							<FormLabel>Item name</FormLabel>
							<Input
								ref={this.nameInputRef}
								maxLength={40}
								value={this.state.title}
								readOnly={this.state.elementToEdit}
								onChange={(e) => this.setState({ title: e.target.value })}
							/>
							<Box pos="relative" sx={{ '& > *': { w: '100%' } }}>
								{dropdown}
							</Box>
						</FormControl>

						{isDev && (
							<React.Fragment>
								<FormControl>
									<FormLabel>Item order</FormLabel>
									<Input maxLength={40} value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} />
									{/* {dropDown} */}
								</FormControl>
							</React.Fragment>
						)}

						<EnvironmentSelection
							label="Save item in"
							authMode={this.state.authorisationMode}
							tenants={this.state.tenants}
							availableTenants={this.props.availableTenants}
							selectTenants={this.state.selectTenants}
							onChangeAuthMode={(value) => this.setState({ authorisationMode: value })}
							onChangeTenants={(value) => this.setState({ tenants: value })}
							onChangeSelectTenants={(value) => this.setState({ selectTenants: value })}
						/>
					</VStack>
				</ModalBody>
				<ModalFooter>
					{this.state.elementToEdit ? (
						<ButtonGroup display="flex" justifyContent="flex-end">
							{!this.props.element && !this.state.loading && (
								<Button variant="ghost" onClick={this.unsetElement} isDisabled={this.state.loading}>
									Cancel
								</Button>
							)}
							<Button onClick={this.replaceElement} isDisabled={this.state.loading}>
								{this.props.element ? 'Share item' : 'Overwrite existing'}
							</Button>
						</ButtonGroup>
					) : (
						<Box display="flex" justifyContent="flex-end">
							<Button
								onClick={this.saveElement}
								isDisabled={this.state.loading || invalidName || invalidTenants}
								data-testid="save-element-modal-save"
							>
								Save
							</Button>
						</Box>
					)}
				</ModalFooter>
			</Modal>
		);
	}

	authModeOptions = PublicOptions.map((item) => {
		return { value: item.value, label: item.label };
	});

	saveElement = leadingDebounce(() => {
		if (!this.state.title) {
			showNotification({ type: NotificationTypes.WARNING, text: WarningMessages.CUSTOM_ELEMENT_NO_TITLE });
			return;
		}

		/* istanbul ignore next */
		let successCb = () => {
			this.setState(initialState);
			this.props.getCustomElements();
			showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.CUSTOM_ELEMENT_SAVED });
			this.setState({ title: '' });
			useModalStore.getState().closeModal(ModalTypes.CUSTOM_ELEMENT_MODAL);
		};

		/* istanbul ignore next */
		let errorCb = () => {
			this.setState({ loading: false });
			showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.CUSTOM_ELEMENT_NOT_SAVED });
		};

		this.setState({ loading: true });

		const userData = getUser();

		if (this.props.element_to_save) {
			this.props.saveCustomElement(
				{
					...this.props.element_to_save,
					tenants: this.state.tenants,
					createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
				},
				this.state.title,
				'',
				this.state.order,
				this.state.authorisationMode,
				successCb,
				errorCb,
			);
		}
	}, config.debounce_times.general);

	renderDropDown = () => {
		const list = this.renderList();

		return (
			<Menu isOpen={Boolean(list?.length)} isLazy>
				<MenuList maxH="calc(50vh - 45px)" overflow="auto" width="100%" onFocus={() => this.nameInputRef.current?.focus()}>
					{list}
				</MenuList>
			</Menu>
		);
	};

	renderList = () => {
		let elements = this.props.elements;

		const list = [];

		const filteredElements = this.props.allowProdSave
			? elements
			: elements.filter((element) => {
					return element.authorisationMode === ApiAuthorisationModes.Private;
				});

		if (filteredElements) {
			filteredElements.forEach((element, i) => {
				if (element.title.toLowerCase().includes(this.state.title.toLowerCase())) {
					list.push(
						<MenuItem
							key={i}
							onClick={() => {
								this.setElementToEdit(element);
								this.nameInputRef.current?.blur();
							}}
							data-testid="dropdown-item"
						>
							{element.title}
						</MenuItem>,
					);
				}
			});
		}

		return list;
	};

	setElementToEdit = (element) => {
		this.setState({
			elementToEdit: element,
			title: element.title,
			order: element.order,
			authorisationMode: element.authorisationMode,
			tenants: element.tenants,
			createdBy: element.createdBy,
			selectTenants: element.authorisationMode === ApiAuthorisationModes.Organization && element.tenants.length,
		});
	};

	unsetElement = () => {
		this.setState({ elementToEdit: undefined, title: '' });
	};

	replaceElement = () => {
		const element = this.state.elementToEdit;

		/* istanbul ignore next */
		const saveError = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Unknown error occurred' });
		};

		/* istanbul ignore next */
		const saveSuccess = () => {
			this.setState(initialState);
			this.props.getCustomElements();
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Successfully overwritten.' });
			this.unsetElement();

			useModalStore.getState().closeModal(ModalTypes.CUSTOM_ELEMENT_MODAL);
		};

		const userData = getUser();

		/* istanbul ignore next */
		this.props.saveCustomElement(
			{
				...this.props.element_to_save,
				tenants: this.state.tenants,
				createdBy: { name: userData.name, id: userData.sub, tenantId: userData[claimsKeys.TENANT_ID] },
			},
			this.state.title,
			element.id,
			this.state.order,
			this.state.authorisationMode,
			saveSuccess,
			saveError,
		);
	};
}

const CustomElementModalWrapper = (props) => {
	const { custom_elements, getCustomElements, saveCustomElement } = useLeftSidebarStore((state) => {
		return {
			custom_elements: state.custom_elements,
			getCustomElements: state.getCustomElements,
			saveCustomElement: state.saveCustomElement,
		};
	}, shallow);

	const availableTenants = useUserStore((state) => state.tenants);

	return (
		<CustomElementModal
			{...custom_elements}
			getCustomElements={getCustomElements}
			saveCustomElement={saveCustomElement}
			{...props}
			availableTenants={availableTenants}
			element={props.data?.element}
		/>
	);
};

export default CustomElementModalWrapper;
